import { settings } from '../config';
import { MxiEventsForUsersPackageOverviewByTailNUmber } from '../types/line';
import { AllLiteHandoverReports, LiteHandoverReportInformation } from '../types/lite';
import { getToken } from './../global/TokenValidator';
class Lite {
    GetAllLiteHandOverReports = async (workPlace: string): Promise<{ status: Boolean, message: string, data: Array<AllLiteHandoverReports>, total: number }> => {
        return fetch(`${settings.HOST}/LiteHandOver/GetAllLiteHandOverReports/${workPlace}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetLiteHandoverReportByID = async (reportId: number): Promise<{ status: Boolean, message: string, data: LiteHandoverReportInformation, }> => {
        return fetch(`${settings.HOST}/LiteHandOver/GetLiteHandoverReportByID/${reportId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    AddLabour = async (reportId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LiteHandOver/AddLabour/${reportId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "user_id": employeeId
            })
        }).then(x => {
            return x.json()
        })
    }
    DeleteLabour = async (reportId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LiteHandOver/DeleteLabour/${reportId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "user_id": employeeId
            })
        }).then(x => {
            return x.json()
        })
    }
    AddIssue = async (reportId: number, issue: string, tailNumber: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LiteHandOver/AddIssue`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "report_id": reportId,
                "issue": issue,
                "tail_number": tailNumber
            })
        }).then(x => {
            return x.json()
        })
    }
    UpdateIssue = async (issueId: number, issue: string, tailNumber: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LiteHandOver/UpdateIssue`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "id": issueId,
                "issue": issue,
                "tail_number": tailNumber
            })
        }).then(x => {
            return x.json()
        })
    }
    DeleteIssue = async (issueId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LiteHandOver/DeleteIssue`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "id": issueId
            })
        }).then(x => {
            return x.json()
        })
    }
    DeleteLabourMC = async (reportId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LiteHandOver/DeleteLabourMC/${reportId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "user_id": employeeId
            })
        }).then(x => {
            return x.json()
        })
    }
    AddLabourMC = async (reportId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LiteHandOver/AddLabourMC/${reportId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "user_id": employeeId
            })
        }).then(x => {
            return x.json()
        })
    }
    CloseLiteHandoverReport = async (workPlace: string): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/LiteHandOver/CloseLiteHandoverReport/${workPlace}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    CreateNewLiteHandoverReport = async (workPlace: string): Promise<{ status: boolean, message: string, data: number }> => {
        return fetch(`${settings.HOST}/LiteHandOver/CreateNewLiteHandoverReport/${workPlace}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    UpdateLiteHandOverReportDates = async (reportId: number, beginDate: Date, endDate: Date): Promise<{ status: boolean, message: string, data: number }> => {
        return fetch(`${settings.HOST}/LiteHandOver/UpdateLiteHandOverReportDates/${reportId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "begin_date": beginDate,
                "end_date": endDate
            })
        }).then(x => {
            return x.json()
        })
    }
    GetMxiEventsForShift = async (reportID: number, isTurnCheck: boolean): Promise<{ status: Boolean, message: string, data: Array<MxiEventsForUsersPackageOverviewByTailNUmber> }> => {
        return fetch(`${settings.HOST}/LiteHandOver/GetMxiEventsForShift/${reportID}/${isTurnCheck}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetHandoverBySearchFilters = async (workPlace: string, searchIssue: string, searchTail: string): Promise<{ status: Boolean, message: string, data: Array<AllLiteHandoverReports>, total: number }> => {
        return fetch(`${settings.HOST}/LiteHandOver/GetHandoverBySearchFilters`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "work_place": workPlace,
                "search_issue": searchIssue,
                "search_tail": searchTail,
            })
        }).then(x => {
            return x.json()
        })
    }
}
const LiteApi = new Lite();
export default LiteApi;