import { FunctionComponent, useEffect, useState } from 'react';
import { Input, Typography, Select, SelectMultiple } from '../../MaterialUiComponents';
import Grid from '@mui/material/Grid2';
import { MenuBook, FormatListNumbered, Description, Title } from '@mui/icons-material';
import { Request, ATAChapter, RatingEnum } from '../../types/capabilityList';
import { combineReducers } from 'redux';

interface ShopOptions {
    name: string,
    id: number
}

interface TextFieldsProps {
    request: Request,
    viewMode: boolean,
    setRequest: Function,
    ATAChapters: ATAChapter[],
    resetShops: boolean,
    setResetShops: Function,
}

const shopOptions: ShopOptions[] = [{ name: 'Select/Unselect All', id: 0 }, { name: 'AVIONICS', id: 1 }, { name: 'COMPONENT', id: 2 }, { name: "COMPOSITE", id: 3 }, { name: 'ENGINE', id: 4 }, { name: 'INTERIOR', id: 5 }, { name: 'METAL', id: 6 }];

const ratingOptions = [
    { name: "A Rating", id: 1 },
    { name: "B Rating", id: 2 },
    { name: "C Rating", id: 3 }
  ];

const TextFields: FunctionComponent<TextFieldsProps> = ({ request, setRequest, viewMode, ATAChapters, resetShops, setResetShops }) => {

    const [toggleSelectAreas, setToggleSelectAreas] = useState<boolean>(false);
    const [selectedShops, setSelectedShops] = useState<number[]>([]);
    const [selectedRating, setSelectedRating] = useState<number>(0);

    useEffect(() => {
        if (resetShops) {
            setResetShops(false);
            setInitialShopState(request);
            setSelectedRating(0)
        }
        else {
            setInitialShopState(request);
            setSelectedRating(request.rating)
        }
    }, [request.request_id, request.version_nr, resetShops]);

    const onUpdateTextFields = (field: keyof Request, value: string) => {
        setRequest((prevState: Request) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const toggleSelectAllAreas = (event: number[]) => {
        if (event.includes(0)) {
            setSelectedShops([1, 2, 3, 4, 5, 6]);
            setToggleSelectAreas(!toggleSelectAreas);
        }
        if (event.includes(0) && toggleSelectAreas === true) {
            setToggleSelectAreas(!toggleSelectAreas);
            setSelectedShops([]);
        }
    }
    const setInitialShopState = (data: Request) => {
        const selectedShops: number[] = [];
        data.shops.forEach(shop => {
            const index = shopOptions.findIndex(s => s.name === shop.shop_name);
            if (index > -1) {
                selectedShops.push(index);
            }
        }
        );
        setSelectedShops(selectedShops);
    }
    const onSelectedShop = (e: number[]) => {
        setSelectedShops(e);
        toggleSelectAllAreas(e);
        const selectedShopNames: { shop_name: string }[] = [];
        e.forEach(index => {
            const shopOption = shopOptions[index];
            if (shopOption) {
                selectedShopNames.push({ shop_name: shopOption.name });
            }
        });
        setRequest((prevState: Request) => ({ ...prevState, shops: selectedShopNames }));
    }

    const onSelectedRating = (e: number) => {
        setSelectedRating(e);
        if(e === RatingEnum.A)
        {
            setSelectedShops([]);
            setRequest((prevState: Request) => ({ ...prevState, rating: e, shops: [], component_name: '', other_text: '' }));
        }
        else if(e === RatingEnum.B)
        {
            setSelectedShops([shopOptions.findIndex(s => s.name === 'ENGINE')]);
            setRequest((prevState: Request) => ({ ...prevState, rating: e, shops: [{ shop_name: 'ENGINE' }], part_number: '', part_name: '', dash_numbers: '', description: '' }));
        }
        else
        {
            setRequest((prevState: Request) => ({ ...prevState, rating: e, component_name: '', other_text: '' }));
        }
      };

    return (
        <>
        <Grid container justifyContent="center" >
            <Typography color='primary' variant='h1' text={request.part_number} align='center' />
        </Grid>
        <Grid container justifyContent="center"  >
            <Grid size={{ xs: 12, md: 5, sm:12 }} justifyContent={"center"}>
                <Input icon={<Description />} disabled={viewMode || (!viewMode && selectedRating === RatingEnum.B)} multiline={true} help_text={""} label_text={"Part number"} value={request.part_number} onTextChange={(text: string) => { onUpdateTextFields("part_number", text) }} type="text" width={95} />
            </Grid>
            <Grid size={{ xs: 12, md: 5, sm:12 }} justifyContent={"center"}>
                <Input icon={<FormatListNumbered />} disabled={viewMode || (!viewMode && selectedRating === RatingEnum.B)} multiline={true} help_text={""} label_text={"Part name"} value={request.part_name} onTextChange={(text: string) => { onUpdateTextFields("part_name", text) }} type="text" width={95} />
            </Grid>
        </Grid>
        <Grid container justifyContent="center">
            <Grid size={{ xs: 12, md: 10, sm:12 }}>
                <Input icon={<Description />} disabled={viewMode || (!viewMode && selectedRating === RatingEnum.B)} multiline={true} help_text={""} label_text={"Dash numbers"} value={request.dash_numbers} onTextChange={(text: string) => { onUpdateTextFields("dash_numbers", text) }} type="text" width={95} />
            </Grid>
        </Grid>

            <Grid container justifyContent="center"  >
                <Grid size={{ xs: 12, md: 5, sm:12 }} justifyContent={"center"}>
                    <Select selectedItem={selectedRating} 
                            onSelectedItem={(e: any) => { onSelectedRating(e.target.value) }}
                            width={90}
                            widthPercentage={true}
                            filter_by_text="Rating"
                            optionsObject={ratingOptions}
                            disabled={viewMode}>
                        
                    </Select>
                </Grid>
                <Grid size={{ xs: 12, md: 5, sm:12 }} >
                </Grid>
            </Grid>
            <Grid container justifyContent="center"  >
                <Grid size={{ xs: 12, md: 5, sm:12 }} justifyContent={"center"}>
                    <SelectMultiple
                        disabled={viewMode || (!viewMode && selectedRating !== RatingEnum.C)}
                        customSelectedText={shopOptions
                            .filter(option => selectedShops.includes(option.id))
                            .map(option => option.name)
                            .join(', ')
                        }
                        optionsObject={shopOptions}
                        selectedItems={selectedShops}
                        widthPercentage={true}
                        width={90}
                        onSelectedItem={(e: any) => { onSelectedShop(e.target.value) }}
                        filter_by_text="Shops"
                    />
                </Grid>
                
                <Grid size={{ xs: 12, md: 5, sm:12 }} >
                        <Input icon={<MenuBook />} disabled={viewMode || (!viewMode && selectedRating === RatingEnum.B)} multiline={true} help_text={""} label_text={"CMM"} value={request.cmm_chapters} onTextChange={(text: string) => { onUpdateTextFields("cmm_chapters", text) }} type="text" width={90} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" >
                <Grid size={{ xs: 12, md: 5, sm:12 }} justifyContent={"center"}>
                    <Select
                        filter_by_text={`ATA Chapters ${request.ata_chapters}`}
                        options={ATAChapters.map((obj: ATAChapter) => obj.ata_chapter)}
                        onSelectedItem={(e: any) => {
                            const temp = ATAChapters.find(s => s.ata_chapter === e.target.value);
                            setRequest((prevState: Request) => ({ ...prevState, ata_chapters: e.target.value, class_type: temp!.rating }));
                        }}
                        disabled={viewMode}
                        widthPercentage={true}
                        width={90}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 5, sm:12 }} justifyContent={"center"}>
                    <Input icon={<Title />} disabled={true} multiline={true} help_text={""} label_text={"Class Type"} value={ATAChapters.find(s => s.ata_chapter === request.ata_chapters)?.rating} onTextChange={(text: string) => { }} type="text" width={90} />
                </Grid>
            </Grid>
            {selectedRating !== RatingEnum.B && (
                <Grid container justifyContent="center">
                    <Grid size={{ xs: 12, md: 10, sm:12 }}>
                        <Input
                            icon={<Description />}
                            disabled={viewMode}
                            multiline={true}
                            help_text=""
                            label_text="Description of capability"
                            value={request.description}
                            onTextChange={(text: string) => onUpdateTextFields("description", text)}
                            type="text"
                            width={95}
                        />
                    </Grid>
                </Grid>
            )}

            {selectedRating === RatingEnum.B && (
                <Grid container justifyContent="center">
                    <Grid size={{ xs: 12, md: 10, sm:12 }}>
                        <Input
                            icon={<Description />}
                            disabled={viewMode}
                            multiline={true}
                            help_text=""
                            label_text="Component name"
                            value={request.other_text}
                            onTextChange={(text: string) => onUpdateTextFields("other_text", text)}
                            type="text"
                            width={95}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 10, sm:12 }}>
                        <Input
                            icon={<Description />}
                            disabled={viewMode}
                            multiline={true}
                            help_text=""
                            label_text="Other text"
                            value={request.component_name}
                            onTextChange={(text: string) => onUpdateTextFields("component_name", text)}
                            type="text"
                            width={95}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    )
}
export default TextFields;

