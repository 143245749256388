
import { FunctionComponent } from 'react';
import { Request, CapabilitySubAssys, RatingEnum } from '../../types/capabilityList';
import { Typography, Table } from '../../MaterialUiComponents';
import { TableRow, TableCell, Paper } from '@mui/material'
import Grid from '@mui/material/Grid2';
import { CheckCircle, RemoveCircle } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { DateTime } from 'luxon';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
    marginTop: {
        marginTop: '16px'
    },
    centeredText: {
        textAlign: 'center',
        overflowWrap: 'break-word',
    }
}));


interface CompareRequestProps {
    request?: Request;
    isChanged: boolean;
    printMode?: boolean;
    rIndex?: number;
}

const CompareRequest: FunctionComponent<CompareRequestProps> = ({ request, isChanged, printMode, rIndex }) => {
    const classes = useStyles();
    const generateTableBody = (row: CapabilitySubAssys, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''} >
                <TableCell>{row.part_number}</TableCell>
                <TableCell>{row.accepted_text}</TableCell>
                <TableCell>{row.created_date_text}</TableCell>
            </TableRow>
        )
    }

    const generateToolTableBody = (row: any, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''} >
                <TableCell>{row.tool_name}</TableCell>
                <TableCell>{row.part_number}</TableCell>
            </TableRow>
        )
    }
    return (
        <Grid >
            {(request !== undefined) &&
                <Paper elevation={3} style={{ padding: '1rem' }}>
                    {printMode && <Typography margin={[0, 0, 1, 0]} color='primary' variant="h6" text={`${rIndex}`} align="center" />}
                    <Grid >
                        <Typography margin={[0, 0, 1, 0]} color='primary' variant="h2" text={`${printMode !== undefined ? "Capability request" : isChanged ? 'Changes made' : 'Original'}`} align="center" />
                        <Grid margin={[4, 2, 4, 2]}>
                            <Typography color='primary' variant="h3" text="Part number" align="center" />
                            <Typography variant='body1' text={`${request!.part_number}`} align='center' />
                        </Grid>
                        <Grid margin={[4, 2, 4, 2]}>
                            <Typography color='primary' variant="h3" text="Part name" align="center" />
                            <Typography variant='body1' text={`${request!.part_name}`} align='center' />
                        </Grid>
                        <Grid margin={[4, 2, 4, 2]}>
                            <Typography color='primary' variant="h3" text="Dash numbers" align="center" />
                            {printMode ? <p className={classes.centeredText}> {`${request!.dash_numbers}`}</p> :
                                <Typography variant='body1' text={`${request!.dash_numbers}`} align='center' />
                            }
                        </Grid>
                        <Grid margin={[4, 2, 4, 2]}>
                            <Typography color='primary' variant="h3" text="CMM chapters" align="center" />
                            {printMode ? <p className={classes.centeredText}>{`${request!.cmm_chapters}`}</p> :
                                <Typography variant='body1' text={`${request!.cmm_chapters}`} align='center' />
                            }
                        </Grid>
                        <Grid margin={[4, 2, 4, 2]}>
                            <Typography color='primary' variant="h3" text="ATA chapters" align="center" />
                            <Typography variant='body1' text={`${request!.ata_chapters}`} align='center' />
                        </Grid>
                        <Grid margin={[4, 2, 4, 2]}>
                            <Typography color='primary' variant="h3" text="Class type" align="center" />
                            <Typography variant='body1' text={`${request!.class_type}`} align='center' />
                        </Grid>
                        <Grid margin={[4, 2, 4, 2]}>
                            <Typography color='primary' variant="h3" text="Description of capability" align="center" />
                            {printMode ? <p className={classes.centeredText}>{`${request!.description}`}</p> :
                                <Typography variant='body1' text={`${request!.description}`} align='center' />
                            }
                        </Grid>
                        <Grid margin={[4, 2, 4, 2]}>
                            <Typography color='primary' variant="h3" text="Manuals needed by shop" align="center" />
                            <Typography variant='body1' text={`${request!.manuals_needed_by_shop}`} align='center' />
                        </Grid>
                        <Grid margin={[4, 2, 4, 2]}>
                            <Typography color='primary' variant="h3" text="Special training" align="center" />
                            <Typography variant='body1' text={`${request!.special_training}`} align='center' />
                        </Grid>
                        <Grid margin={[4, 2, 4, 2]}>
                            <Typography color='primary' variant="h3" text="Rating" align="center" />
                            <Typography variant='body1' text={`${RatingEnum[request!.rating]}`} align='center' />
                        </Grid>
                        {request!.rating === RatingEnum.B && (
                            <>
                                <Grid margin={[4, 2, 4, 2]}>
                                    <Typography color='primary' variant="h3" text="Component name" align="center" />
                                    <Typography variant='body1' text={`${request!.component_name}`} align='center' />
                                </Grid>
                                <Grid margin={[4, 2, 4, 2]}>
                                    <Typography color='primary' variant="h3" text="Other text " align="center" />
                                    <Typography variant='body1' text={`${request!.other_text}`} align='center' />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid margin={[4, 0, 4, 0]}>
                        <Grid>
                            <Typography margin={[2, 0, 2, 0]} color='primary' variant='h2' text={"Shops"} align='center' />
                        </Grid>
                        <Grid container>
                            {request.shops.map((Shop, index) => (
                                <Grid size={{ xs: 3}}  container key={index}>
                                    <Grid container justifyContent={'center'}>
                                        <Grid><Typography fontWeight="bolder" text={`${Shop.shop_name}`} align='center' /></Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid margin={[4, 0, 4, 0]}>
                        <Grid>
                            <Typography margin={[2, 0, 2, 0]} color='primary' variant='h2' text={"Permissions"} align='center' />
                        </Grid>
                        <Grid container>
                            <Grid size={{ xs: 3}} container>
                                <Grid container justifyContent={'center'}>
                                    <Grid><Typography text={"OH"} align='center' /></Grid>
                                    <Grid container justifyContent={'center'}>
                                        {
                                            request!.is_oh ?
                                                <CheckCircle color='success' /> :
                                                <RemoveCircle color="error" />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={{ xs: 3}} container>
                                <Grid container justifyContent={'center'}>
                                    <Grid><Typography text={"Inspect Tested"} align='center' /></Grid>
                                    <Grid container justifyContent={'center'}>
                                        {
                                            request!.is_inspection_test ?
                                                <CheckCircle color='success' /> :
                                                <RemoveCircle color="error" />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={{ xs: 3}} container>
                                <Grid container justifyContent={'center'}>
                                    <Grid><Typography text={"MOD'S"} align='center' /></Grid>
                                    <Grid container justifyContent={'center'}>
                                        {
                                            request!.is_mods ?
                                                <CheckCircle color='success' /> :
                                                <RemoveCircle color="error" />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={{ xs: 3}} container>
                                <Grid container justifyContent={'center'}>
                                    <Grid><Typography text={"Repair"} align='center' /></Grid>
                                    <Grid container justifyContent={'center'}>
                                        {
                                            request!.is_repair ?
                                                <CheckCircle color='success' /> :
                                                <RemoveCircle color="error" />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.marginTop} container justifyContent="center" direction="column">
                        <Grid>
                            <Typography color='primary' variant='h2' text={"Sub Assy"} align='center' />
                        </Grid>
                        <Grid>
                            <Table
                                headers={["Sub Assy", "Accepted Text", "Date"]}
                                rows={request?.capability_sub_assys.filter(s => s.is_deleted === false)}
                                generateBody={(row: any, index: number) => generateTableBody(row, index)}
                                pagination={false}
                                skipIndexCol={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid margin={[4, 0, 4, 0]}>
                        <Typography color='primary' margin={[4, 0, 4, 0]} variant="h2" text="Tools" align="center" />
                    </Grid>
                    <Grid>
                        <Table
                            headers={["Tool name", "Part number"]}
                            rows={request?.capability_tools}
                            generateBody={(row: any, index: number) => generateToolTableBody(row, index)}
                            pagination={false}
                            skipIndexCol={true}
                        />
                    </Grid>
                    <Grid margin={[4, 2, 4, 2]}>
                        <Typography color='primary' variant="h3" text="Accepted date" align="center" />
                        <Typography 
                            variant='body1' 
                            // originalrequest and request are not the same type
                            text={
                                request?.accepted_date
                                    ? typeof request.accepted_date === 'string'
                                        ? DateTime.fromISO(request.accepted_date).toFormat('dd.MM.yyyy HH:mm') // Handle ISO string
                                        : DateTime.fromJSDate(new Date(request.accepted_date)).toFormat('dd.MM.yyyy HH:mm') // Handle Date object
                                    : 'No date available'
                            }
                            align='center' 
                        />
                    </Grid>
                    <Grid margin={[4, 2, 4, 2]}>
                        <Typography color='primary' variant="h3" text="Accepted text" align="center" />
                        <Typography variant='body1' text={`${request!.accepted_text}`} align='center' />
                    </Grid>
                    <Grid margin={[4, 0, 4, 0]}>
                        <Typography color='primary' variant="h3" text="Jira link" align="center" />
                        <Typography variant='body1' text={`${request!.jira_link}`} align='center' />
                    </Grid>
                </Paper>
            }
        </Grid>
    )
}

export default CompareRequest;