import { useState, FunctionComponent, useEffect } from 'react';
import { Grid, TableCell, TableRow, IconButton, Button, Box, Autocomplete, TextField } from '@mui/material';
import { Edit, Check, Close, Delete, AddCircle, CheckCircle, RemoveCircle, Info, Announcement } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Table, Typography, Dialog, DatePicker, Input } from './../../MaterialUiComponents';
import { CertificateData, CertificateRights, CertificateRating, CertificateAircraftCategory,RatingsDifference } from './../../types/certificates';
import { RootState } from '../../reducer';
import { useSelector } from 'react-redux';
import CertificatesApi from '../../api/certificates';
import ProcessApproval from '../Certificates/ProcessApproval';
import CertificateInfo from '../Certificates/CertificateInfo';
import { DateTime } from 'luxon';
import { TableColumns } from '../../types/table';

const useStyles = makeStyles(theme => ({
    certificate: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(3)
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    save: {
        color: 'green'
    },
    cancel: {
        color: 'orange'
    },
    delete: {
        color: 'red'
    },
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
    changes: {
        backgroundColor: theme.palette.info.main,
        padding: theme.spacing(2)
    },
    rowChanged: {
        color: theme.palette.info.main
    }
}));

type CertificateProps = {
    selectedCertificate: CertificateData,
    closeCertificate: Function,
    viewType: 'view' | 'edit' | 'approval' | 'history',
    setLoading: Function,
    userType: string,
    shouldBeAbleToClose?: boolean | undefined,
    ratingsDifference?: RatingsDifference
}

const sortTableColumns = [
    { label: "Ratings", key: "rating_name" },
    { label: "Aircraft / Category", key: "aircraft_category_name" },
    { label: "Expiration date", key: "expiration_date" },
    { label: "Status", key: "expired_date_status" },
] as TableColumns[];



const Certificate: FunctionComponent<CertificateProps> = ({ selectedCertificate, closeCertificate, viewType, setLoading, shouldBeAbleToClose, ratingsDifference}) => {
    const [order_by_text, setOrderByText] = useState("created_date");
    const [ascending, setAscending] = useState(true);
    const [editRow, setEditRow] = useState(-1);
    const [ratings, setRatings] = useState<CertificateRating[]>([]);
    const [aircraftCategories, setAircraftCategories] = useState<CertificateAircraftCategory[]>([]);
    const [tempRating, setTempRating] = useState('');
    const [tempAircraftCategory, setTempAircraftCategory] = useState('');
    const [emptyValueValidation, setemptyValueValidation] = useState('');
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [changesMade, setChangesMade] = useState<string[]>([]);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [ratingOptions, setRatingOptions] = useState<string[]>([]);
    const [aircraftOptions, setAircraftOptions] = useState<string[]>([]);
    const [jiraLink, setJiraLink] = useState("");
    const store = useSelector((state: RootState) => state);
    const userId = store.user.me.user_id;

    useEffect(() => {
        getRatingsAndAircrafts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateTableBody = (row: CertificateRights, index: number) => {
        return (
            <TableRow className={editRow !== -1 && editRow !== index ? classes.overlay : index % 2 ? classes.evenRows : ''}>
                <TableCell>{editRow === index ?
                    <Autocomplete
                        fullWidth
                        style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                        options={ratingOptions}
                        value={tempRating}
                        noOptionsText="No Ratings Found"
                        clearOnEscape
                        autoHighlight
                        onChange={(e: any, newValue: string | null) => { newValue !== null && setTempValueForRow(newValue, 'ratings') }}
                        renderInput={(params) => (
                            <TextField {...params} label="Ratings" margin="none" variant="outlined" size="medium" fullWidth />
                        )} />
                    : row.rating_name}</TableCell>
                <TableCell>{editRow === index ?
                    <Autocomplete
                        fullWidth
                        style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                        options={aircraftOptions}
                        value={tempAircraftCategory}
                        noOptionsText="No categories found"
                        clearOnEscape
                        autoHighlight
                        onChange={(e: any, newValue: string | null) => { newValue !== null && setTempValueForRow(newValue, 'aircraft') }}
                        renderInput={(params) => (
                            <TextField {...params} label="Aircraft Categories" margin="none" variant="outlined" size="medium" fullWidth />
                        )} />
                    : row.aircraft_category_name}</TableCell>
                <TableCell>{editRow === index ? <DatePicker onDateChange={(date: Date) => onDateChange(date)} type={"Date"} label="Choose date" selected={selectedDate} /> : row.expiration_date_text} </TableCell>
                <TableCell>{viewType === "approval" && row.is_row_modified === true ? <Announcement className={classes.rowChanged} /> : row.expired_date_status !== undefined ? row.expired_date_status.toString() === 'Valid' || row.expired_date_status.toString() === 'updated' ? <CheckCircle className={classes.save} />
                    :
                    row.expired_date_status.toString() === 'Expiring' ? <Info color="secondary" />
                        :
                        <RemoveCircle color="error" /> : ''
                }</TableCell>
                <TableCell>
                    {editRow !== index && viewType === 'edit' ?
                        <IconButton onClick={() => setEditMode(index, row)} color="default" >
                            <Edit />
                        </IconButton>
                        :
                        editRow === index && viewType === 'edit' &&
                        <Grid>
                            <IconButton onClick={() => saveEdit(index)} className={classes.save} >
                                <Check />
                            </IconButton>
                            <IconButton onClick={() => cancelEdit()} className={classes.cancel}>
                                <Close />
                            </IconButton>
                            <IconButton onClick={() => deleteRow(index, row)} className={classes.delete} >
                                <Delete />
                            </IconButton>
                            {emptyValueValidation && <Typography text={emptyValueValidation} color="error" fontWeight="bold" />}
                        </Grid>
                    }
                </TableCell>
            </TableRow>
        )
    }
    const setEditMode = (index: number, row: CertificateRights) => {
        if (editRow === -1) {
            setEditRow(index);
            setTempRating(row.rating_name);
            setTempAircraftCategory(row.aircraft_category_name);
            const expDateToString = row.expiration_date.toString();
            const expDateToDate = DateTime.fromISO(expDateToString).toJSDate();

            //Because backend returns row.expiration_date as string. Here we need to parse that string to date so that
            //selectedDate can have the same date as row.expiration_date before user starts editing the dates
            if (typeof row.expiration_date === "string") {
                setSelectedDate(expDateToDate);
            }
            else {
                setSelectedDate(row.expiration_date);
            }
        }
    }
    const onDateChange = (date: Date) => {
        setSelectedDate(date);
    }
    const addRow = () => {
        if (editRow === -1) {
            setTempAircraftCategory('');
            setTempRating('');
            setSelectedDate(null);
            selectedCertificate.certificate_rights.push({ rating_name: '', aircraft_category_name: '', expiration_date: new Date() });
            setEditRow(selectedCertificate.certificate_rights.length);
        }
    }
    const OnOrderByTitles = (given_order_by_text: string) => {
        let temp_ascending = true;
        if (order_by_text === given_order_by_text) { temp_ascending = ascending === false ? true : false; }
        setAscending(temp_ascending);
        setOrderByText(given_order_by_text);
    }
    const setTempValueForRow = (value: string, type: string) => {
        if (type === 'ratings') {
            setTempRating(value);
        }
        if (type === 'aircraft') {
            setTempAircraftCategory(value);
        }
        if (type === 'expiry') {
            setSelectedDate(new Date(value));
        }
    }
    const saveEdit = (index: number) => {
        let selectedRow = selectedCertificate.certificate_rights[index - 1];
        if (tempRating === '' || tempAircraftCategory === '' || selectedDate === null) {
            setemptyValueValidation('Missing values. Please add them or cancel changes');
        }
        else {

            if (selectedRow.rating_name === '') {
                setChangesMade([...changesMade, `NEW ROW: ${tempRating}, ${tempAircraftCategory}, ${DateTime.fromJSDate(new Date(selectedDate)).toFormat('dd.LL.yyyy')}`]);
            }
            else {
                setChangesMade([...changesMade, `EDIT ROW: ${selectedRow.rating_name}, ${selectedRow.aircraft_category_name}, ${DateTime.fromJSDate(new Date(selectedRow.expiration_date)).toFormat('dd.LL.yyyy')} ==> ${tempRating}, ${tempAircraftCategory}, ${DateTime.fromJSDate(selectedDate).toFormat('dd.LL.yyyy')}`]);
            }
            if (tempRating !== '') {
                selectedRow.rating_name = tempRating;
                selectedRow.certificate_rating_id = ratings.find(r => r.rating === tempRating)?.id;
            }
            if (tempAircraftCategory !== '') {
                selectedRow.aircraft_category_name = tempAircraftCategory;
                selectedRow.certificate_aircraft_category_id = aircraftCategories.find(a => a.aircraft_category === tempAircraftCategory)?.id
            }
            if (selectedDate !== undefined) {
                selectedRow.expiration_date = selectedDate;
                selectedRow.expiration_date_text = DateTime.fromJSDate(selectedDate).toFormat('dd.LL.yyyy');
            }
            setEditRow(-1);
            setemptyValueValidation('');
        }
    }
    const cancelEdit = () => {
        setemptyValueValidation('');
        setEditRow(-1);
    }
    const deleteRow = (index: number, row: CertificateRights) => {
        setChangesMade([...changesMade, "DELETED ROW: " + row.rating_name + ', ' + row.aircraft_category_name + ', ' + row.expiration_date_text]);
        selectedCertificate.certificate_rights.splice(index - 1, 1);
        setEditRow(-1);
    }
    const submitCertificate = () => {
        if (jiraLink.length !== 0) {
            let certificateToSubmit: CertificateData = {
                certificate_rights: selectedCertificate.certificate_rights,
                mechanic_employee_id: selectedCertificate.mechanic_employee_id,
                owner_employee_id: userId,
                modified_changes_in_text: changesMade,
                jira_link: jiraLink
            }
            setConfirmDialog(false);
            setLoading(true);
            CertificatesApi.CreateNewCertificateForEmployee(certificateToSubmit).then(res => {
                setLoading(false);
            })
        }
    }

    const getRatingsAndAircrafts = () => {
        setLoading(true);
        CertificatesApi.GetAllCertificationRatings().then(ratingsRes => {
            const ratings = ratingsRes.data || [];
            setRatings(ratings);
            const filteredRatings = ratings
                .filter(rating => rating.visible === true)
                .map(rating => rating.rating);
            setRatingOptions(filteredRatings);

            CertificatesApi.GetAllCertififcateAircraftCategories().then(aircraftsRes => {
                const aircraftCategories = aircraftsRes.data || [];
                setAircraftCategories(aircraftCategories);
                const filteredAircrafts = aircraftCategories
                    .filter(aircraft => aircraft.visible === true)
                    .map(aircraft => aircraft.aircraft_category);
                setAircraftOptions(filteredAircrafts);
                setLoading(false);
            })
        })
    }
    const classes = useStyles();
    return (
        <Grid className={classes.certificate}>
            <CertificateInfo viewType={viewType} selectedCertificate={selectedCertificate} infoType="top" closeCertificate={() => closeCertificate()} shouldBeAbleToClose={shouldBeAbleToClose} />
            {(selectedCertificate.modified_changes_in_text !== undefined && selectedCertificate.modified_changes_in_text !== null && viewType === "approval") &&
                <Grid item xs={12} lg={8} className={classes.changes}>
                    {selectedCertificate.modified_changes_in_text!.map(change => (
                        <Typography variant="body1" fontWeight="bold" align="center" text={change} />
                    ))}
                </Grid>
            }
            <Grid container xs={12}>
                <Grid item xs={12} lg={8}>
                    {
                        selectedCertificate.certificate_rights.length > 0 ?
                            <Table
                                sortTableColumns={sortTableColumns}
                                headers={["Ratings", "Aircraft / Category", "Expiration date", "Status", ""]}
                                rows={selectedCertificate.certificate_rights}
                                generateBody={(row: any, index: number) => generateTableBody(row, index)}
                                OnOrderByTitles={(order_by_text: string) => OnOrderByTitles(order_by_text)}
                                pagination={false}
                                skipIndexCol={true}
                            />
                            :
                            <Typography text={"You have no authorization"} variant='h1' />
                    }
                </Grid>
            </Grid>
            <Grid container xs={12}>
                {viewType === "edit" &&
                    <div>
                        <Grid item xs={12}>
                            <IconButton onClick={() => addRow()} className={classes.save} >
                                <AddCircle />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={editRow !== -1 || changesMade.length === 0} variant="contained" color="primary" onClick={() => setConfirmDialog(true)}>Submit Certificate for Approval</Button>
                        </Grid>
                    </div>
                }
                <Dialog
                    visible={confirmDialog}
                    max_width={"md"} title={"Confirm these changes for approval"}
                    context={
                        <div>
                            {
                                changesMade.map(row =>
                                    <Box display="flex" justifyContent="center" width="100%" m={1} p={1}>
                                        <Typography variant="body1" align="center" text={row} color="primary" />
                                    </Box>
                                )}
                            <Grid container justifyContent={'center'}>
                                <Input width={60} multiline={false} help_text={""} label_text={"Please provide jira link"} value={jiraLink} onTextChange={(text: string) => setJiraLink(text)} type="text" />
                            </Grid>
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <Box p={1}>
                                    <Button variant="contained" color="primary" onClick={() => submitCertificate()}>Confirm Certificate for approval</Button>
                                </Box>
                            </Box>
                        </div>
                    }
                    onClose={(status: any) => setConfirmDialog(false)}
                    strict={false}
                    show_options={false}
                />
            </Grid>
            <CertificateInfo viewType={viewType} selectedCertificate={selectedCertificate} infoType="bottom" closeCertificate={() => { }} shouldBeAbleToClose={shouldBeAbleToClose} />
            {viewType === 'approval' &&
                <ProcessApproval
                    ratingsDifference={ratingsDifference!}
                    approvalSendBy={selectedCertificate.approval_sent_by_full_name!}
                    changes={selectedCertificate.modified_changes_in_text!}
                    certificates={selectedCertificate.certificate_rights}
                    employeeId={selectedCertificate.mechanic_employee_id}
                    closeCertificate={() => closeCertificate()}
                    certificateId={selectedCertificate.certificate_id}
                    approverId={userId}
                />
            }
        </Grid>
    );
};
export default Certificate;
