import { useEffect, useState } from 'react';
import { Tabs, Table, Input, Typography, SelectMultiple } from '../../MaterialUiComponents';
import { TableRow, TableCell, LinearProgress, Button } from '@mui/material'
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import { Check, History, Search } from '@mui/icons-material';
import { ATAChapter, Request, RatingEnum } from '../../types/capabilityList';
import CapabilityListApi from '../../api/capabilityList';
import CapabilityRequest from './CapabilityRequest';
import RequestHistory from './RequestHistory';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import Print from './Print';
import { TableColumns } from '../../types/table';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
    magnifierIcon: {
        cursor: "pointer"
    },
    greenCheckMark: {
        color: 'green'
    },
    Button: {
        marginBottom: theme.spacing(4),
    },
    resetButton: {
        height: '30px',
        marginTop: theme.spacing(2)
    }
}));

const shops = ["AVIONICS", "COMPONENT", "COMPOSITE", "ENGINE", "INTERIOR", "METAL"];
const sortTableColumns : TableColumns[] = [
    { label: "Part number", key: "part_number" },
    { label: "Part name", key: "part_name" },
    { label: "Class type", key: "class_type" },
    { label: "ATA", key: "ata_chapters" },
    { label: "Inspection/Tested", key: "is_inspection_test" },
    { label: "MOD´s", key: "is_mods" },
    { label: "OH", key: "is_oh" },
    { label: "Repair", key: "is_repair" },
    { label: "Rating", key: "rating" },
    { label: "Accepted Date", key: "accepted_date_text" },
    { label: "", key: "" },
];

const sortTableColumnsEngineTab : TableColumns[] = [
    { label: "Component name", key: "component_name" },
    { label: "ATA", key: "ata_chapters" },
    { label: "Rating", key: "rating" },
    { label: "Other text", key: "other_text" },
    { label: "Accepted Date", key: "accepted_date_text" },
    { label: "", key: "" },
];

const initialRequest: Request = ({
    accepted_date: new Date(),
    part_name: "",
    part_number: "",
    dash_numbers: "",
    cmm_chapters: "",
    ata_chapters: "",
    description: "",
    special_training: "",
    manuals_needed_by_shop: "",
    is_inspection_test: false,
    is_repair: false,
    is_oh: false,
    is_mods: false,
    capability_sub_assys: [],
    capability_tools: [],
    shops: [],
    class_type: "",
    accepted_text: "",
    jira_link: "",
    rating: RatingEnum.Unknown,
    component_name: "",
    other_text: ""
});

const CapabilityList = () => {
    const [tabValue, setTabValue] = useState<number>(0);
    const [filteredCapabilityRequests, setFilteredCapabilityRequests] = useState<Request[]>([]);
    const [capabilityRequests, setCapabilityRequests] = useState<Request[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [history, setHistory] = useState<boolean>(false);

    const [partSubAssyNumber, setPartSubAssyNumber] = useState<string>('');
    const [partName, setPartName] = useState<string>('');

    const [requestHistory, setRequestHistory] = useState<Request[]>([]);
    const [viewMode, setViewMode] = useState<boolean>(true);
    const [ATAChapters, setATAChapters] = useState<ATAChapter[]>([]);

    const [requestId, setRequestId] = useState<number>(-1);
    const classes = useStyles();

    const store = useSelector((state: RootState) => state);
    const isCapabilityAdmin = store.user.me.user_clearence_groups.includes("Capability Edit");
    const isCapabilityUser = store.user.me.user_clearence_groups.includes("Capability View");
    const isShopLeader = store.user.me.user_clearence_groups.includes("Shop Team Leaders");

    const shopTabs = isCapabilityAdmin ? ["ALL SHOPS", "AVIONICS", "COMPONENT", "COMPOSITE", "ENGINE", "INTERIOR", "METAL", "A Rating" ,  "Create/Update"] : ["ALL SHOPS", "AVIONICS", "COMPONENT", "COMPOSITE", "ENGINE", "INTERIOR", "A Rating", "METAL"];
    isShopLeader && shopTabs.push("PRINT");

    const [request, setRequest] = useState<Request>(initialRequest);

    const [selectedShops, setSelectedShops] = useState<string[]>(shops);
    const [filteredShopsForPrint, setFilteredShopsForPrint] = useState<Request[]>(capabilityRequests);
    const [filteredAtaChapters, setFilteredAtaChapters] = useState<string[]>([]);

    const onTabValueClicked = (tabVal: number) => {
        if (tabVal === 0) {
            fetchInitialData();
        }
        if (tabVal !== 8) {
            setRequestId(-1);
        }
        if (tabVal !== 0) {
            setHistory(false);
            setRequestHistory([]);
        }
        if (tabVal === 8) {
            setRequest(initialRequest);
        }
        if (tabVal > 0 && tabVal < 8) {
            onFilterRequests(partSubAssyNumber, partName, tabVal, filteredAtaChapters);
        }
    }

    const fetchInitialData = () => {
        setLoading(true);
        GetAllAtaChaptersRatings();
        CapabilityListApi.GetAllCapabilityRequests().then(res => {
            setCapabilityRequests(res.data);
            setFilteredShopsForPrint(res.data);
            setFilteredCapabilityRequests(res.data);
            setLoading(false);
        });
    }

    const sortRequestsByShop = (tabVal: number) => {
        if(tabVal === 4){ // Engine tab
            const filteredCapabilityRequests = capabilityRequests.filter((request) =>
                request.shops.some((shop) => shop.shop_name === "ENGINE")
            );
            return filteredCapabilityRequests;
        }
        else if (tabVal > 0 && tabVal < 7) {
            const shopTab = shops[tabVal - 1];
            const filteredCapabilityRequests = capabilityRequests.filter((request) =>
                request.shops.some((shop) => shop.shop_name === shopTab)
            );
            return filteredCapabilityRequests;
        }
        else if(tabVal === 7){ // A rating tab
            const filteredCapabilityRequests = capabilityRequests.filter((request) =>
                request.rating === RatingEnum.A
            );
            return filteredCapabilityRequests;
        }
        else if (tabVal === 0) {
            return capabilityRequests;
        }
    }

    useEffect(() => {
        if (tabValue === 0) {
            fetchInitialData();
        }
    }, [tabValue]);

    const GetAllAtaChaptersRatings = () => {
        CapabilityListApi.GetAllAtaChaptersRatings().then(res => {
            setATAChapters(res.data);
            setATAChaptersRating(res.data);
        });
    }

    const setATAChaptersRating = (ataChapter: ATAChapter[]) => {
        if (request?.class_type === "" || request?.class_type === undefined) {
            const rating = ataChapter.find(s => s.ata_chapter === request?.ata_chapters)?.rating;
            if (rating !== "" && rating !== undefined) {
                setRequest((prevState: Request) => ({
                    ...prevState!,
                    class_type: rating,
                }));
            }
        }
    }

    const onFilterRequests = (partSubAssyNumber: string, partName: string, tabVal: number, ataChapter: string[]) => {
        const sortedByShop = sortRequestsByShop(tabVal);
        let filterByInputText: Request[] = sortedByShop !== undefined ? sortedByShop : [];
        if (ataChapter.length > 0) {
            const filteredbyAtaArray = filterByInputText.filter(obj => ataChapter.includes(obj.ata_chapters));
            filterByInputText = [...filteredbyAtaArray];
        }
        if (partName !== "" && partSubAssyNumber === "") {
            filterByInputText = filterByInputText.filter(s => s.part_name.toUpperCase().includes(partName.toUpperCase()));
        }
        else if (partSubAssyNumber !== "" && partName === "") {
            const filterSubAssys = filterByInputText.filter((request) =>
                request.capability_sub_assys.some((subAssy) => subAssy.part_number.includes(partSubAssyNumber))
            );
            const filterByPartNumbers = filterByInputText.filter(s => s.part_number.toUpperCase().includes(partSubAssyNumber.toUpperCase()));
            filterByInputText = [...filterSubAssys, ...filterByPartNumbers];
        }
        else if (partSubAssyNumber !== "" && partName !== "") {
            const filterSubAssys = filterByInputText.filter((request) =>
                request.capability_sub_assys.some((subAssy) => subAssy.part_number.includes(partSubAssyNumber))
            );
            const filterByPartNumbers = filterByInputText.filter(s => s.part_number.toUpperCase().includes(partSubAssyNumber.toUpperCase()));
            const filterByPartName = filterByInputText.filter(s => s.part_name.toUpperCase().includes(partName.toUpperCase()));
            filterByInputText = [...filterSubAssys, ...filterByPartNumbers, ...filterByPartName];
        }
        setFilteredCapabilityRequests(filterByInputText);
    }

    const onHistory = (requestId: number | undefined) => {
        if (requestId !== undefined) {
            setLoading(true);
            CapabilityListApi.GetRequestHistoryById(requestId).then(res => {
                setRequestHistory(res.data);
                setLoading(false);
                setHistory(true);
                setViewMode(true);
            });
        }
    }

    const onSetEditRequest = (rowRequestId: number) => {
        setLoading(true);
        GetAllAtaChaptersRatings();
        CapabilityListApi.GetRequestById(rowRequestId).then(res => {
            setRequest(res.data);
            setLoading(false);
            setRequestId(rowRequestId);
            setTabValue(8);
        });
    }

    const generateTableBody = (row: Request, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''} >
                <TableCell>{row.part_number} </TableCell>
                <TableCell>{row.part_name} </TableCell>
                <TableCell>{row.class_type} </TableCell>
                <TableCell>{row.ata_chapters} </TableCell>
                <TableCell>{row.is_inspection_test && <Check className={classes.greenCheckMark} />} </TableCell>
                <TableCell>{row.is_mods && <Check className={classes.greenCheckMark} />} </TableCell>
                <TableCell>{row.is_oh && <Check className={classes.greenCheckMark} />} </TableCell>
                <TableCell>{row.is_repair && <Check className={classes.greenCheckMark} />} </TableCell>
                <TableCell>{RatingEnum[row.rating]} </TableCell>
                <TableCell>{row.accepted_date_text} </TableCell>
                <TableCell>{<Button onClick={() => { onHistory(row.request_id) }}><History color='success' /></Button>}</TableCell>
                <TableCell><Search className={classes.magnifierIcon} onClick={() => { row.request_id !== undefined && onSetEditRequest(row.request_id) }} /></TableCell>
            </TableRow>
        )
    }

    const generateTableBodyEngineTab = (row: Request, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''} >
                <TableCell>{row.component_name} </TableCell>
                <TableCell>{row.ata_chapters} </TableCell>
                <TableCell>{row.rating} </TableCell>
                <TableCell>{row.other_text} </TableCell>
                <TableCell>{row.accepted_date_text} </TableCell>
                <TableCell>{<Button onClick={() => { onHistory(row.request_id) }}><History color='success' /></Button>}</TableCell>
                <TableCell><Search className={classes.magnifierIcon} onClick={() => { row.request_id !== undefined && onSetEditRequest(row.request_id) }} /></TableCell>
            </TableRow>
        )
    }

    const filterByShopOptions = (e: string[]) => {
        if (e.length === shops.length) {
            setFilteredShopsForPrint(capabilityRequests);
        }
        else {
            const matchingRequests = capabilityRequests.filter(request =>
                request.shops.some(shop => e.includes(shop.shop_name))
            );
            setFilteredShopsForPrint(matchingRequests);
        }
        setSelectedShops(e);
    }
    const onClearFilters = () => {
        setPartSubAssyNumber('');
        setPartName('');
        setFilteredAtaChapters([]);
        setFilteredCapabilityRequests(capabilityRequests);
    }

    return (
        <Grid>
            {(isCapabilityUser || isCapabilityAdmin) ?
                <Grid>
                    {
                        tabValue !== 9 &&
                        <Tabs
                            fixedTabs
                            tabNames={shopTabs}
                            tabValue={tabValue}
                            onChangeTabValue={(tabValue: number) => { setTabValue(tabValue); onTabValueClicked(tabValue) }}
                        />
                    }
                    {loading && <LinearProgress />}
                    {capabilityRequests.length > 0 &&
                        ((tabValue !== 8 && tabValue !== 9) && !history) &&
                        <Grid>
                            <Grid container spacing={2} justifyContent={'center'} style={{ marginBottom: '18px' }}>
                                <Grid container size={{ xs: 2, sm: 2, md: 2, lg:1.5 }}>
                                    <Button className={classes.resetButton} variant="contained" color="primary" size="small" onClick={() => onClearFilters()}  >Clear filters</Button>
                                </Grid>
                                <Grid container size={{ xs: 12, sm: 6, md: 4, lg:2 }}>
                                    <Input multiline={false} help_text={""} label_text={"Search by part or sub assy number"} value={partSubAssyNumber} onTextChange={(text: string) => { setPartSubAssyNumber(text); onFilterRequests(text, partName, tabValue, filteredAtaChapters); }} type="text" width={80} />
                                </Grid>
                                <Grid container size={{ xs: 12, sm: 6, md: 4, lg:2 }} >
                                    <Input multiline={false} help_text={""} label_text={"Part name"} value={partName} onTextChange={(text: string) => { { setPartName(text); onFilterRequests(partSubAssyNumber, text, tabValue, filteredAtaChapters) } }} type="text" width={80} />
                                </Grid>
                                <Grid style={{ marginTop: '16px', width: '200px', marginRight: '18px' }} >
                                    <SelectMultiple
                                        options={[...[''], ...ATAChapters.map((obj: ATAChapter) => obj.ata_chapter)]}
                                        onSelectedItem={(e: any) => {
                                            setFilteredAtaChapters(e.target.value);
                                            onFilterRequests(partSubAssyNumber, partName, tabValue, e.target.value)
                                        }}
                                        widthPercentage={true}
                                        selectedItemsString={filteredAtaChapters}
                                        width={100}
                                        filter_by_text={"Filter by ata chapter"}
                                    />
                                </Grid>
                                <Grid style={{ marginTop: '16px', marginLeft:'16px'}}>
                                    <SelectMultiple
                                        options={shops}
                                        required
                                        onSelectedItem={(e: any) => { filterByShopOptions(e.target.value) }}
                                        selectedItemsString={selectedShops}
                                        width={150}
                                        widthPercentage
                                        filter_by_text={"Choose shops to print"}
                                    />
                                </Grid>
                            </Grid>

                            {tabValue !== 4 &&
                            <Grid>
                                <Table
                                    sortTableColumns={sortTableColumns}
                                    headers={["Part number", "Part name", "Class type", "ATA", "Inspection/Tested", "MOD´s", "OH", "Repair", "Rating", "Accepted Date", "History", ""]}
                                    rows={filteredCapabilityRequests}
                                    generateBody={(row: Request, index: number) => generateTableBody(row, index)}
                                    pagination={true}
                                    defaultRowsPerPage={100}
                                    skipIndexCol={true}
                                />
                            </Grid>
                            }           

                            {tabValue === 4 &&
                            <Grid>
                                <Table
                                    sortTableColumns={sortTableColumnsEngineTab}
                                    headers={["Component name", "ATA", "Rating", "Other text", "Accepted Date", "History", ""]}
                                    rows={filteredCapabilityRequests}
                                    generateBody={(row: Request, index: number) => generateTableBodyEngineTab(row, index)}
                                    pagination={true}
                                    defaultRowsPerPage={100}
                                    skipIndexCol={true}
                                />
                            </Grid>
                            }
                        </Grid>
                    }
                    {history &&
                        <RequestHistory
                            requestHistory={requestHistory}
                            setHistory={setHistory}
                            setRequestHistory={setRequestHistory}
                            ATAChapters={ATAChapters}
                            setViewMode={setViewMode}
                            setRequest={setRequest}
                            setTabValue={setTabValue}
                        />
                    }
                    {tabValue === 8 &&
                        <CapabilityRequest
                            isCapabilityAdmin={isCapabilityAdmin}
                            requestId={requestId}
                            request={request}
                            setRequest={setRequest}
                            setViewMode={setViewMode}
                            viewMode={viewMode}
                            ATAChapters={ATAChapters}
                            setTabValue={setTabValue}
                        />
                    }
                    {(tabValue === 9) &&
                        <Print filteredShopsForPrint={filteredShopsForPrint} setTabValue={setTabValue} />
                    }
                </Grid>
                :
                <Typography variant='h1' text={`You are not authorized to view this page`} align='center' />
            }
        </Grid>
    )
}

export default CapabilityList;