import { FunctionComponent, useEffect, useState } from "react";
import { HangarChecklistDto } from "../../types/checklist";
import { Grid, Grid2, TableCell, TableRow, Button, Checkbox } from "@mui/material";
import { ChecklistTaskDto } from "../../types/checklist";
import { Table, Dialog, Snackbar} from '../../MaterialUiComponents';
import ChecklistApi from "../../api/checklists";
import { Check } from '@mui/icons-material';

type ChecklistDetailsModalProps = {
    checklist: HangarChecklistDto | undefined;
    checkListDialogOpen: Function;
    isChecklistAdmin: boolean;
    refreshChecklists: Function;
    setLoading: Function;
    loading: boolean;
}

const ChecklistDetailsModal: FunctionComponent<ChecklistDetailsModalProps> = ({ checklist, checkListDialogOpen, isChecklistAdmin, refreshChecklists, setLoading, loading }) => {

    const [tasks, setTasks] = useState<ChecklistTaskDto[]>([]);
    const [checklistStatus, setChecklistStatus] = useState<boolean>(false);
    const [closeChecklistDialogOpen, setChecklistDialogOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");

    useEffect(() => {
        setLoading(true);
        refresh();
    }, [checklist]);

    const refresh = () => {
        if (checklist !== undefined) {
            setChecklistStatus(checklist?.IsActive);
            ChecklistApi.GetChecklistsTasks(checklist.Id).then(res => {
                setTasks(res.data);
                setLoading(false);
            });
        }
    }
    const checkOffTask = (task: ChecklistTaskDto) => {
        setLoading(true);
        ChecklistApi.UpdateChecklistTask(task.Id, !task.IsDone, true).then(res => {
            refresh();
        });
    }

    const checkOffAsNotApplicableTask = (task: ChecklistTaskDto) => {
        setLoading(true);   
        ChecklistApi.UpdateChecklistTask(task.Id, task.IsDone, !task.IsApplicable).then(res => {
            refresh();
        });
    }

    const generateTableBody = (row: ChecklistTaskDto, index: number) => {
        return (
            <TableRow key={index}>
                <TableCell>{row.TaskName}</TableCell>
                {(isChecklistAdmin && checklist?.IsActive) ?
                    <TableCell width="10">
                        <Checkbox
                            checked={!row.IsApplicable}
                            onChange={() => checkOffAsNotApplicableTask(row)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </TableCell> :
                    <TableCell width="10">{row.IsApplicable === false ? <Check style={{ color: 'green' }} /> : ""}</TableCell>
                }
                {(isChecklistAdmin && checklist?.IsActive) ?
                    <TableCell width="10">
                        {row.IsApplicable === true &&
                        <Checkbox
                            checked={row.IsDone}
                            onChange={() => checkOffTask(row)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />}
                    </TableCell> :
                    <TableCell width="10">{row.IsDone === true ? <Check style={{ color: 'green' }} /> : ""}</TableCell>
                }
            </TableRow>
        );
    }

    const onCloseChecklist = () => {
        if (checklist !== undefined) {
            setLoading(true);
            ChecklistApi.InactiateChecklist(checklist.Id).then(res => {
                if (res.status === true) {
                    setChecklistStatus(!checklistStatus);
                    checkListDialogOpen(false);
                    refreshChecklists();
                    setLoading(false);
                }
            });
        }
    }
    
    const onFinishedChecklist = () => {
        const allApplicableAreDone = tasks.filter(task => task.IsApplicable).every(task => task.IsDone === true);

        if (allApplicableAreDone) {
          setChecklistDialogOpen(true);
        } else {
            setSnackbarMessage("Not all applicable tasks are done yet!");
        }
      };

    return (
        <Grid2>
            {(isChecklistAdmin && checklist?.IsActive) &&
                <Grid container >
                    <Button
                        onClick={() => onFinishedChecklist()}
                        variant='contained'
                        color={'primary'}
                        style={{ marginTop: '18px' }}
                    >
                        Finish Checklist
                    </Button>
                </Grid>
            }
            <Table
                headers={["Task Name", "N/A", "Done"]}
                rows={tasks}
                generateBody={(row: ChecklistTaskDto, index: number) => generateTableBody(row, index)}
                pagination={true}
                defaultRowsPerPage={10}
                skipIndexCol={true}
                loading={loading}
            />
            <Dialog
                visible={closeChecklistDialogOpen}
                max_width={"sm"}
                title={"Are you sure you want to close this checklist?"}
                context={<></>}
                onClose={(status: Boolean) => { status === true ? onCloseChecklist() : setChecklistDialogOpen(false) }}
                strict={false}
                show_options={true}
            />
            <Snackbar
                onSnackBarClose={() => setSnackbarMessage("")}
                open={snackbarMessage.length > 0}
                message={snackbarMessage}
                autoHide={true}
                horizontalPosition='right'
                severity={"warning"}
            >
            </Snackbar>
        </Grid2>
    );
}
export default ChecklistDetailsModal;