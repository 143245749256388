import StaffApi from '../../api/staffmanagement';
import { StaffManagementAreas, StaffManagementBays, StaffMtpEmployee, BayTailInfo } from '../../types/staffManagement';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { Tabs, LinearProgress, Dialog, SelectMultiple } from '../../MaterialUiComponents';
import GroupList from './GroupList';
import AboutEmployee from './AboutEmployee';
import CardComponent from './CardComponent';
import useGetWindowSize from '../../components/Hooks/useGetWindowSize';
import UserApi from '../../api/userApi';
import { User } from '../../types/user';
import { makeStyles } from '@mui/styles';
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
import SkillsApi from '../../api/skillsApi';
import { SelectObject } from './../../types/certificates';
import Chip from '@mui/material/Chip';
import staffApi from "../../api/staffmanagement";
import SecurityApi from '../../api/security';
import OverviewApi from '../../api/overview';
import { useParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles(theme => {
    return {
        MItem: {
            display: 'inline-block',
            marginBottom: '16px',
            width: '100%',
        }
    }
});

const attendanceStatusOptions = [
    { id: 0, name: "Away" },
    { id: 1, name: "In Work" },
    { id: 2, name: "Night Shift" },
    { id: 3, name: "Vacation/Absence" },
];

type AttendanceStatusCount = {
    attendanceStatus: string,
}

export interface DragInfo {
    draggingFromBay: StaffManagementBays | undefined,
    draggingFromGroup: StaffManagementAreas | undefined,
    draggingFromBayIndex: number,
    draggingFromGroupIndex: number,
    draggedEmployeeIndex: number,
    fromSearchDraggedEmployee: string,
    fromSearchDraggedEmployeeNr: number,
    fromSearchDraggedEmployeeStatus: string
}

const StaffManagement = () => {
    const [windowWidth, windowHeight] = useGetWindowSize();
    const [tabValue, setTabValue] = useState<number>(0);
    const [islLoading, setIsLoading] = useState<boolean>(false);
    const store = useSelector((state: RootState) => state);
    const [staffMaganagementBaysLoaded, setStaffMaganagementBaysLoaded] = useState<boolean>(false);
    const [aboutEmployeeDialogOpen, setAboutEmployeeDialogOpen] = useState<boolean>(false);
    const [staffManagementBays, setStaffManagementBays] = useState<StaffManagementBays[]>([]);

    const [allEmployees, setAllEmployees] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | StaffMtpEmployee | null>();
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [dragging, setDragging] = useState<boolean>(false);
    const [selectedAttendanceStatus, setSelectedAttendanceStatus] = useState<number[]>([0, 1, 2, 3]);
    const [selectedSkillStatus, setSelectedSkillStatus] = useState<number[]>([]);
    const [distinctBays, setDistinctBays] = useState<string[]>();
    const [skillOptions, setSkillOptions] = useState<SelectObject[]>([]);

    const { urlTabValue } = useParams();
    const classes = useStyles();
    const theme = useTheme();

    const dragInfo = useRef<DragInfo>({
        draggingFromBay: undefined,
        draggingFromGroup: undefined,
        draggingFromBayIndex: -1,
        draggingFromGroupIndex: -1,
        draggedEmployeeIndex: -1,
        fromSearchDraggedEmployee: "",
        fromSearchDraggedEmployeeNr: -1,
        fromSearchDraggedEmployeeStatus: "Away"
    });

    const url = useMemo(() => {
        return new URL(window.location.href.replace("#", ""))
    }, []);
    const meGroups = store.user.me.user_clearence_groups;

    const isYodeckView = useMemo(() => {
        return url.href.includes("staff-management/yodeck")
    }, [url]);

    const isStmAdmin = useMemo(() => {
        return isYodeckView ? false : meGroups.includes("Staff Management Edit");
    }, [isYodeckView])

    const isSkillsView = isYodeckView ? true : meGroups.includes("Mechanic Skills Edit") || meGroups.includes("Mechanic Skills View");
    const [attendanceStatus, setAttendanceStatus] = useState<AttendanceStatusCount[]>([]);

    useEffect(() => {
        getAllMaintenerUsers();
        refresh();
    }, []);

    const [workpackagesInBay, setWorkpackagesInBay] = useState<BayTailInfo[]>([]);

    useEffect(() => {
        const statusArray = [] as AttendanceStatusCount[];
        if (tabValue === 0) {
            staffManagementBays.forEach(bay => {
                bay.areas.forEach(area => {
                    area.employyes_in_group.forEach(employee => {
                        statusArray.push({ attendanceStatus: employee.employee_attendance_status });
                    });
                });
            });
        }
        else {
            staffManagementBays[tabValue - 1].areas.forEach(area => {
                area.employyes_in_group.forEach(employee => {
                    statusArray.push({ attendanceStatus: employee.employee_attendance_status });
                });
            });
        }
        setAttendanceStatus(statusArray);
    }, [tabValue]);

    const refresh = useCallback(() => {
        setIsLoading(true);
        StaffApi.GetAllStaffManagement().then(res => {
            if (isYodeckView) {
                const tempUrl = url.href.split("=")[1];
                const bayName = tempUrl.replace("_", " ");

                //Since bayName and s.bay for bay_1 and bay_2 do not match exactly, we need to filter by the first 5 letters.
                const data = res.data.filter(s => s.bay.substring(0, 5).toUpperCase() === bayName.substring(0, 5).toUpperCase());
                setStaffManagementBays(data);
                setDistinctBays(["", bayName]);
                setTabValue(1);
            } else {
                const statusArray = [] as AttendanceStatusCount[];
                setStaffManagementBays(res.data);
                res.data.forEach(bay => {
                    bay.areas.forEach(area => {
                        area.employyes_in_group.forEach(employee => {
                            statusArray.push({ attendanceStatus: employee.employee_attendance_status });
                        });
                    });
                });
                setAttendanceStatus(statusArray);
                setDistinctBays(res.bays);
                res.bays.unshift("All Bays");
            }
            setIsLoading(false);
            if (urlTabValue) {
                setTabValue(parseInt(urlTabValue));
            }
            !staffMaganagementBaysLoaded && setStaffMaganagementBaysLoaded(true);
        });
        SkillsApi.GetSkills().then(res => {
            if (res.status === true) {
                const filteredSkills: SelectObject[] = res.data.filter(skill => skill.Visible).map((skill, index) => ({ name: skill.Skill, id: index }));
                if (skillOptions.length === 0) {
                    setSkillOptions(filteredSkills);
                }
            }
        })

        SecurityApi.AircraftInHangar().then(async (hangarRes) => {
            const bayNames = hangarRes.data.map((bay) => bay.bay_name);
            const packagePromises = hangarRes.data.map((bay, index) =>
                OverviewApi.GetPackageInformationByTailNumber(bay.tail_number, ["IN WORK"], false).then((packageInformationRes) => {
                    return {
                        tailNumber: bay.tail_number,
                        bay: bayNames[index],
                        workPackages: packageInformationRes.status ? packageInformationRes.data : [],
                    };
                })
            );
            const allPackageInformation = await Promise.all(packagePromises);
            const tempPackageInfo = allPackageInformation.filter((info) => info !== null) as BayTailInfo[];
            setWorkpackagesInBay(tempPackageInfo);
        });


    }, [url, isYodeckView, staffManagementBays, staffMaganagementBaysLoaded, skillOptions]);

    const getAllMaintenerUsers = () => {
        UserApi.getAllUsers().then(res => {
            setAllEmployees(res);
        });
    }

    //This function is used to scroll the page when the user is dragging an employee.
    const handleDragOver = (e: any) => {
        const mouseY = e.clientY;
        if (mouseY < 100 && dragging) {
            window.scrollTo({
                top: window.pageYOffset - 10,
            });
        }
        else if (mouseY > window.innerHeight - 100) {
            window.scrollTo({
                top: window.pageYOffset + 10,
            });
        }
        e.preventDefault();
    };

    const handleAboutEmployeeDialogOpen = useCallback((dialogOpen: boolean, user: StaffMtpEmployee | User | null) => {
        setSelectedUser(user);
        setAboutEmployeeDialogOpen(dialogOpen);
    }, [setSelectedUser, setAboutEmployeeDialogOpen]);

    const returnAttendanceStatusString = useMemo(() => {
        const array = [] as Array<string>;
        selectedAttendanceStatus.map(item => {
            if (item === 3) {
                array.push("Vacation");
            }
            else {
                array.push(attendanceStatusOptions[item].name);
            }
        })
        return array;
    }
        , [selectedAttendanceStatus]);

    const returnMechanicsSkillsString = useMemo(() => {
        const array = [] as Array<string>;
        selectedSkillStatus.map(item => {
            array.push(skillOptions[item].name);
        })
        return array;
    }
        , [selectedSkillStatus, skillOptions]);

    const memoObj = useMemo(() => { return { bay: "", areas: [] } }, [])

    const moveEmployee = useCallback((destinationAreaIndex: number, bay: StaffManagementBays) => {
        const destinationArea = bay.areas[destinationAreaIndex];
        let movedEmployee: StaffMtpEmployee | undefined;
        let isSearchComponent = false;

        if (dragInfo?.current?.draggingFromBayIndex === -1 && dragInfo?.current?.draggingFromGroupIndex === -1) {//if the dragged employee is from the search component.
            const addBay = bay.bay;
            const addArea = bay.areas[destinationAreaIndex].area;
            const workstation_name = `${addBay} in ${addArea}`;
            const employee = allEmployees.find((employee) => employee.Id === dragInfo?.current?.fromSearchDraggedEmployeeNr);
            movedEmployee = {
                user_id: dragInfo.current.fromSearchDraggedEmployeeNr,
                employee_name: dragInfo.current.fromSearchDraggedEmployee,
                workstation_name: workstation_name,
                user_name: employee ? employee.userName : "",
                employee_attendance_status: dragInfo.current.fromSearchDraggedEmployeeStatus,
                user_image: employee ? employee.user_image : "",
                mobile: employee ? employee.mobile : "",
                job_title: employee ? employee.job_title : "",
                crs_number: employee ? employee.crs_number : "",
                employee_abbreviation: employee ? employee.employee_abbreviation : "",
                employee_skills: [] as string[],
                is_team_leader: false
            };
            isSearchComponent = true;
        }
        else {

            // Get the source and destination areas
            // ! Recommend using other mehcanism that '!' but I'm fairly sure it will never be null
            const sourceArea = dragInfo!.current!.draggingFromBay?.areas[dragInfo!.current!.draggingFromGroupIndex];
            // Remove the employee from the source area
            // ! Recommend using other mehcanism that '!' but I'm fairly sure it will never be null
            movedEmployee = sourceArea?.employyes_in_group.at(dragInfo!.current!.draggedEmployeeIndex);
        }
        // Add the employee to the destination area
        // destinationArea.employyes_in_group.push(movedEmployee);
        if (movedEmployee) {
            const username = movedEmployee.user_name.split('@')[0];
            const mgtBayCopy = [...staffManagementBays];
            const newArr = mgtBayCopy.map((mapBay) => {
                if (mapBay.bay === bay.bay) {
                    const newAreas = mapBay.areas.map((area) => {
                        if ((area.area === destinationArea.area) && movedEmployee) {
                            area.employyes_in_group.push(movedEmployee);
                        }
                        return area;
                    });
                    mapBay.areas = newAreas;
                }
                if (mapBay.bay === dragInfo.current?.draggingFromBay?.bay) {
                    const newAreas = mapBay.areas.map((area) => {
                        if ((area.area === dragInfo!.current!.draggingFromBay?.areas[dragInfo!.current!.draggingFromGroupIndex].area) && movedEmployee) {
                            area.employyes_in_group = area.employyes_in_group.filter((emp) => emp.user_name !== username);
                        }
                        return area;
                    });
                    mapBay.areas = newAreas;
                }
                return mapBay;
            })
            setStaffManagementBays(newArr);
            if (isSearchComponent) {
                setIsLoading(true);
                onAddEmployeeToGroup(username, destinationArea.area, false);
            }
            else {
                setIsLoading(true);
                const sourceArea = dragInfo!.current!.draggingFromBay?.areas[dragInfo?.current?.draggingFromGroupIndex ?? -1];
                if (sourceArea) {
                    staffApi.DeleteUserFromGroup(username, sourceArea?.area).then((res) => {
                        const isLeaderId = movedEmployee?.user_id;
                        const isLeader = movedEmployee?.is_team_leader === undefined ? false : movedEmployee?.is_team_leader;
                        if (isLeader && isLeaderId) {
                            staffApi.RemoveLeaderRole(isLeaderId, sourceArea?.area).then((res) => { });
                        }
                        onAddEmployeeToGroup(username, destinationArea.area, isLeader);
                    });
                }
            }
        }
    }, [staffManagementBays]);

    const onAddEmployeeToGroup = useCallback((userName: string, area: string, refreshTrue: boolean) => {
        staffApi.AssignUserToGroup(userName, area).then((res) => {
            setIsLoading(false);
            if (res.isPageRefresh === true || refreshTrue) {
                refresh();
            }
        });
    }, [refresh]);

    return (
        <Grid onDragOver={(e: any) => handleDragOver(e)}>
            <Grid>
                {islLoading && <LinearProgress />}
            </Grid>
            <Tabs
                tabNames={distinctBays ? distinctBays : []}
                onChangeTabValue={(tabValue: number) => { setTabValue(tabValue) }}
                tabValue={tabValue}
            />
            <Grid item xs={12}>
                {isStmAdmin &&
                    <Grid item container direction="row" sm={showSearch ? 6 : 12} md={showSearch ? 8 : 12} lg={showSearch ? 9.5 : 12} xl={showSearch ? 10 : 12}
                        style={{
                            position: "sticky",
                            top: 45,
                            zIndex: 1000,
                            background: theme.palette.background.default,
                            padding: "10px 10px",
                        }}
                    >
                        <Grid item style={{ marginBottom: '6px' }} >
                            <SelectMultiple
                                optionsObject={attendanceStatusOptions}
                                onSelectedItem={(e: any) => setSelectedAttendanceStatus(e.target.value)}
                                selectedItems={selectedAttendanceStatus}
                                width={200}
                                filter_by_text={"Filter by Attendance"}
                            />
                            {isSkillsView &&
                                < SelectMultiple
                                    optionsObject={skillOptions}
                                    onSelectedItem={(e: any) => setSelectedSkillStatus(e.target.value)}
                                    selectedItems={selectedSkillStatus}
                                    width={200}
                                    filter_by_text={"Filter by Mechanic Skill"}
                                />
                            }
                            <Button style={{ marginTop: '16px', marginLeft: '16px' }} variant="contained" color="primary" size="medium" onClick={() => setShowSearch(!showSearch)} >{showSearch ? "Hide Search" : "Show Search"}</Button>
                        </Grid>
                        <Grid style={{ marginTop: '18px', marginLeft: '16px' }}>
                            <Chip style={{ marginRight: '8px', backgroundColor: 'orange', fontSize: '16px', fontWeight: '700' }} label={attendanceStatus.filter(status => status.attendanceStatus === "Away").length} variant="filled" />
                            <Chip style={{ marginRight: '8px', backgroundColor: '#02c412', fontSize: '16px', fontWeight: '700' }} label={attendanceStatus.filter(status => status.attendanceStatus === "In Work").length} variant="filled" />
                            <Chip style={{ marginRight: '8px', backgroundColor: '#028cf5', fontSize: '16px', fontWeight: '700' }} label={attendanceStatus.filter(status => status.attendanceStatus === "Night Shift").length} variant="filled" />
                            <Chip style={{ marginRight: '8px', backgroundColor: 'pink', fontSize: '16px', fontWeight: '700' }} label={attendanceStatus.filter(status => status.attendanceStatus === "Vacation").length} variant="filled" />
                        </Grid>
                    </Grid>
                }
                {(tabValue === 0 && staffMaganagementBaysLoaded) &&
                    <Grid container direction="row" style={{ padding: "10px" }}>
                        <Grid item sm={showSearch ? 6 : 12} md={showSearch ? 8 : 12} lg={showSearch ? 9.5 : 12} xl={showSearch ? 10 : 12}>
                            <Grid style={windowWidth < 1700 ? { columnCount: '1', columnGap: '16px' } : windowWidth <= 2400 ? { columnCount: '2', columnGap: '16px' } : { columnCount: '3', columnGap: '16px' }}>
                                {staffManagementBays.map((bay, bayIndex) => (
                                    <Grid key={bayIndex} className={classes.MItem} >
                                        <CardComponent
                                            bay={bay.bay}
                                            banner_color='black'
                                            bayTailInfo={workpackagesInBay.find((workPackage: BayTailInfo) => bay.bay?.toLowerCase().includes(workPackage?.bay?.toLowerCase()))}
                                            isbay={true}
                                        >
                                            <GroupList
                                                isStmAdmin={isStmAdmin}
                                                setDragging={setDragging}
                                                bay={bay}
                                                bayIndex={bayIndex}
                                                isSearchComponent={false}
                                                employees={allEmployees}
                                                setLoading={setIsLoading}
                                                handleAboutEmployeeDialog={handleAboutEmployeeDialogOpen}
                                                attendanceStatus={returnAttendanceStatusString}
                                                mechanicsSkills={returnMechanicsSkillsString}
                                                dragInfo={dragInfo}
                                                moveEmployee={moveEmployee}
                                                tabValue={tabValue}
                                            />
                                        </CardComponent >
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid sm={6} md={4} lg={2.5} xl={2} item style={{ position: 'sticky', top: '0', right: '0', visibility: showSearch ? 'visible' : 'hidden' }}>
                            <Grid style={{ position: 'fixed', top: '115px', right: '6', overflowY: 'auto' }}>
                                <Grid style={{ width: '300px', padding: '6px', margin: '6px', height: `${windowHeight - 150}px` }}>
                                    {
                                        <GroupList
                                            isStmAdmin={isStmAdmin}
                                            setDragging={setDragging}
                                            bay={memoObj}
                                            bayIndex={-1}
                                            isSearchComponent={true}
                                            employees={allEmployees}
                                            setLoading={setIsLoading}
                                            handleAboutEmployeeDialog={handleAboutEmployeeDialogOpen}
                                            attendanceStatus={returnAttendanceStatusString}
                                            mechanicsSkills={returnMechanicsSkillsString}
                                            dragInfo={dragInfo}
                                            moveEmployee={moveEmployee}
                                            tabValue={tabValue}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    tabValue !== 0 &&
                    <Grid container direction="row" style={{ padding: "10px" }}>
                        <Grid item sm={6} md={8} lg={9} xl={10} >
                            <Grid style={windowWidth < 1700 ? { columnCount: '1', columnGap: '16px' } : windowWidth <= 2400 ? { columnCount: '2', columnGap: '16px' } : { columnCount: '3', columnGap: '16px' }}>
                                <GroupList
                                    isStmAdmin={isStmAdmin}
                                    setDragging={setDragging}
                                    bayIndex={tabValue - 1}
                                    bay={staffManagementBays[tabValue - 1]}
                                    isSearchComponent={false}
                                    employees={allEmployees}
                                    setLoading={setIsLoading}
                                    handleAboutEmployeeDialog={handleAboutEmployeeDialogOpen}
                                    attendanceStatus={returnAttendanceStatusString}
                                    mechanicsSkills={returnMechanicsSkillsString}
                                    dragInfo={dragInfo}
                                    moveEmployee={moveEmployee}
                                    tabValue={tabValue}
                                />
                            </Grid>
                        </Grid>
                        {((showSearch) && isStmAdmin) &&
                            <Grid sm={6} md={4} lg={3} xl={2} item style={{ position: 'sticky', top: '0' }}>
                                <Grid style={{ position: 'fixed', top: '115px', overflowY: 'auto' }}>
                                    <Grid style={{ width: '300px', padding: '6px', margin: '6px', height: `${windowHeight - 150}px` }}>
                                        {showSearch &&
                                            <GroupList
                                                isStmAdmin={isStmAdmin}
                                                setDragging={setDragging}
                                                bay={{ bay: "", areas: [] }}
                                                bayIndex={-1}
                                                isSearchComponent={true}
                                                employees={allEmployees}
                                                setLoading={setIsLoading}
                                                handleAboutEmployeeDialog={handleAboutEmployeeDialogOpen}
                                                attendanceStatus={returnAttendanceStatusString}
                                                mechanicsSkills={returnMechanicsSkillsString}
                                                dragInfo={dragInfo}
                                                moveEmployee={moveEmployee}
                                                tabValue={tabValue}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                }
            </Grid>
            <Dialog
                visible={aboutEmployeeDialogOpen}
                max_width={"md"} title={selectedUser ? selectedUser!.employee_name : ""}
                context={
                    <Grid item xs={12}>
                        <AboutEmployee
                            tabNames={isSkillsView ? ["about employee", "tools", "certificate", "Skills", "Team leader"] : ["about employee", "tools", "certificate", "Team leader"]}
                            user={selectedUser!}
                            refresh={refresh}
                        />
                    </Grid>
                }
                onClose={(status: any) => (setAboutEmployeeDialogOpen(false))}
                strict={false}
                show_options={false}
            />
        </Grid>
    )
}
export default StaffManagement;