import { useEffect, useState, useRef } from 'react';
import { Grid, Button } from '@mui/material';
import { SelectMultiple, Tabs } from '../../MaterialUiComponents';
import ShortTermHangarPlanTab from './ShortTermHangarPlanTab';
import LongTermHangarPlanTab from './LongTermHangarPlanTab';
import { hangarPlanLineOptions } from '../../properties/lineProperties';
import hangarPlanApi from '../../api/hangarPlanApi';
import { EventInfo } from '../../types/mobiScroll';
import { LinearProgress } from '../../MaterialUiComponents';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';

const statusOptions = [
    { id: 0, name: "COMPLETE" },
    { id: 1, name: "ACTV" },
    { id: 2, name: "COMMIT" },
    { id: 3, name: "IN WORK" },
];

const defaultHangarFilters = [hangarPlanLineOptions.kefHangarBay3, hangarPlanLineOptions.kefHangarBay4, hangarPlanLineOptions.kefHangarBay5];
const defaultStatusFilters = [2];

const storedHangars = localStorage.getItem('hangarFilters');
const storedStatus = localStorage.getItem('statusFilters');

if (storedStatus === null || storedStatus === undefined) {
    localStorage.setItem("statusFilters", JSON.stringify(defaultStatusFilters));
}

if (storedHangars === null || storedHangars === undefined) {
    localStorage.setItem("hangarFilters", JSON.stringify(defaultHangarFilters));
}

const getHangarFiltersFromLocalStorage = (): string[] => {
    const storedFilters = localStorage.getItem("hangarFilters");
    if (storedFilters) {
        return JSON.parse(storedFilters);
    } else {
        return defaultHangarFilters;
    }
};

const getStatusFiltersFromLocalStorage = (): number[] => {
    const storedFilters = localStorage.getItem("statusFilters");
    if (storedFilters) {
        return JSON.parse(storedFilters);
    } else {
        return defaultStatusFilters;
    }
};

export const HangarPlan: React.FC = () => {
    const [tabValue, setTabValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [calendarData, setCalendarData] = useState<EventInfo[]>([]);
    const gridRef = useRef<HTMLDivElement>(null);
    const [childWidth, setChildWidth] = useState(0);
    const drawerOpen = useSelector((state: RootState) => state.data.data.drawerOpen);

    const [selectedHangars, setSelectedHangars] = useState<string[]>(() => {
        return getHangarFiltersFromLocalStorage();
    });

    const [selectedStatusOptions, setSelectedStatusOptions] = useState<number[]>(() => {
        return getStatusFiltersFromLocalStorage();
    });

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        setLoading(true);

        let fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 90);
        let toDate = new Date();
        toDate.setDate(toDate.getDate() + 90);

        const tempSelectedStatus: string[] = selectedStatusOptions.map(el => statusOptions[el].name);

        await hangarPlanApi.GetHangarPlanPackages(fromDate, toDate, tempSelectedStatus, selectedHangars).then(res => {
            setLoading(false);
            const data = res.data;
            const tasks: EventInfo[] = [];
            data.forEach(packet => {
                const startDate = new Date(packet.scheduled_start_date);
                const endDate = new Date(packet.scheduled_end_date);

                // Adjust startDate to the nearest 4-hour block
                const startHour = startDate.getHours();
                const adjustedStartHour = Math.floor(startHour / 4) * 4;
                startDate.setHours(adjustedStartHour, 0, 0, 0);

                // Adjust endDate to the nearest 4-hour block
                let endHour = endDate.getHours();
                if (startHour === endHour) {
                    endHour = endHour + 1;
                }
                const adjustedEndHour = Math.ceil((endHour + endDate.getMinutes() / 60) / 4) * 4;
                endDate.setHours(adjustedEndHour, 0, 0, 0);

                tasks.push({
                    "start": startDate,
                    "end": endDate,
                    "id": packet.wo_barcode,
                    "title": packet.tail_number + " " + packet.wo_name,
                    "resource": packet.wo_location,
                    "color": ReturnStatusColor(packet.wo_status, undefined),
                    "wo_status": packet.wo_status,
                    "parts_amounts": packet.basket,
                    "parts_available": packet.avail_basket,
                    "start_date_text": packet.scheduled_start_date_text,
                    "end_date_text": packet.scheduled_end_date_text,
                    "assigned_employees": packet.assigned_employees,
                    "mt_man_hours": packet.man_hours,
                    "mxi_man_hours": packet.mxi_man_hours,
                    "mxi_man_hours_days": packet.mxi_man_hours_days,
                    "wo_includes_clean_task": packet.wo_includes_clean_task,
                    "need_cert": packet.need_cert,
                    "heavy_bool": packet.heavy_bool,
                });
            });
            setCalendarData(tasks);
        });
    }
    const ReturnStatusColor = (status: string, unassigned: boolean | undefined) => {

        if (unassigned === true) {
            return "#FF0000"
        }
        switch (status) {
            case ("COMPLETE"):
                return "#497523";
            case ("ACTV"):
                return "#F5B53F";
            case ("PAUSE"):
                return "";
            case ("COMMIT"):
                return "#E4B7B7";
            default:
                return "#506E9F";
        }
    }


    useEffect(() => {
        const measureWidth = () => {
            if (gridRef.current) {
                setChildWidth(gridRef.current.offsetWidth);
            }
        };
    
        measureWidth();
    
        window.addEventListener("resize", measureWidth);
    
        // Clean up the event listener
        return () => {
            window.removeEventListener("resize", measureWidth);
        };
    }, [drawerOpen]);

    const computedWidth = drawerOpen ? childWidth - 210 : childWidth;
    return (
        <Grid xs={12} ref={gridRef}>
            {loading && <LinearProgress />}
            <Grid>
                <Tabs
                    fixedTabs
                    tabNames={["Short term", "Long term"]}
                    tabValue={tabValue}
                    onChangeTabValue={setTabValue}
                />
                <Grid container>
                    <Grid>
                        <SelectMultiple
                            options={Object.values(hangarPlanLineOptions)}
                            onSelectedItem={(e: any) => {
                                setSelectedHangars(e.target.value);
                                localStorage.setItem("hangarFilters", JSON.stringify(e.target.value))
                            }}
                            selectedItemsString={selectedHangars}
                            width={200}
                            filter_by_text={"Filter by Hangars"}
                        />
                    </Grid>
                    <Grid >
                        <SelectMultiple
                            optionsObject={statusOptions}
                            onSelectedItem={(e: any) => {
                                setSelectedStatusOptions(e.target.value);
                                localStorage.setItem("statusFilters", JSON.stringify(e.target.value))
                            }}
                            selectedItems={selectedStatusOptions}
                            width={200}
                            filter_by_text={"Filter by status"}
                        />
                    </Grid>
                    <Button onClick={() => refresh()} variant="outlined" style={{ marginLeft: '8px', height: '20px', marginTop: '20px' }}>Refresh</Button>
                </Grid>
                <Grid style={{ width: `${computedWidth}px` }}>
                    {tabValue === 0 && <ShortTermHangarPlanTab data={calendarData} hangars={selectedHangars} onRefresh={refresh}/>}
                    {tabValue === 1 && <LongTermHangarPlanTab data={calendarData.filter(x => x.heavy_bool == true)} hangars={selectedHangars} onRefresh={refresh}/>}
                </Grid>
            </Grid>
        </Grid>
    );
}