import { FunctionComponent, useEffect, useState } from "react";
import ChecklistApi from "../../api/checklists";
import { Grid, TableCell, TableRow, IconButton } from '@mui/material';
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
import { Table, Input, Dialog } from '../../MaterialUiComponents';
import { AddCircle, Edit } from '@mui/icons-material';
import { ChecklistTemplateTaskDto } from "../../types/checklist";
import EditColumn from '../Security/EditColumn';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
    save: {
        color: 'green'
    },
}));

type ChecklistTemplateProps = {
    setLoading: Function;
}

const ChecklistTemplate: FunctionComponent<ChecklistTemplateProps> = ({ setLoading }) => {

    const store = useSelector((state: RootState) => state);
    const isChecklistAdmin = store.user.me.user_clearence_groups.includes("Checklist Edit");

    const [editRow, setEditRow] = useState<number | undefined>(undefined);
    const [taskName, setTaskName] = useState<string>("");
    const [templateTasks, setTemplateTasks] = useState<ChecklistTemplateTaskDto[]>([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [selectedTask, setSelectedTask] = useState<ChecklistTemplateTaskDto | undefined>(undefined);
    const [createTemplateTask, setCreateTemplateTask] = useState<boolean>(false);

    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        refresh();
    }, []);

    const refresh = () => {
        setLoading(true);
        ChecklistApi.GetChecklistTemplateTasks().then(res => {
            setTemplateTasks(res.data);
            setLoading(false);
        });
    }

    const onSelectRow = (row: ChecklistTemplateTaskDto, index: number) => {
        setEditRow(index);
        setTaskName(row.TaskName);
        setSelectedTask(row);
    }

    const onCancelChanges = () => {
        setEditRow(-1);
        setTaskName("");
    }

    const onModifyTemplateTask = () => {
        setLoading(true);
        ChecklistApi.UpdateTemplateTask(templateTasks[editRow! - 1].Id, taskName).then(res => {
            refresh();
            setEditRow(-1);
        });
    }
    const onDeleteDialogConfirm = () => {
        if (selectedTask !== undefined) {
            setLoading(true);
            ChecklistApi.DeleteTemplateTask(selectedTask.Id).then(res => {
                setEditRow(-1);
                setDeleteDialogOpen(false);
                refresh();
            });
        }
    }

    const generateTableBody = (row: ChecklistTemplateTaskDto, index: number) => {
        return (editRow === index ?
            <TableRow>
                <TableCell>
                    <Input
                        multiline={true}
                        help_text={""}
                        label_text={"Name"}
                        value={taskName}
                        onTextChange={(text: string) => setTaskName(text)}
                        type="text"
                        width={100}
                    />
                </TableCell>
                <EditColumn
                    abort={true}
                    index={index}
                    saveChanges={() => onModifyTemplateTask()}
                    editRow={index}
                    setEditRow={() => onCancelChanges()}
                    deleteRow={() => setDeleteDialogOpen(true)}
                />
                <TableCell></TableCell>
            </TableRow>
            :
            <TableRow key={index}>
                <TableCell>{row.TaskName}</TableCell>
                <TableCell>{isChecklistAdmin && <IconButton onClick={() => onSelectRow(row, index)}><Edit /></IconButton>}</TableCell>
            </TableRow>
        )
    }
    const initializeTemplateTask = () => {
        setTaskName("");
    }

    const AddTemplateTask = () => {
        setLoading(true);
        ChecklistApi.AddTemplateTask(taskName).then(res => {
            setCreateTemplateTask(false);
            refresh();
        });
    }

    return (
        <Grid>
            {isChecklistAdmin && <Grid container direction="column" alignItems="center" style={{ marginTop: '28px' }}>
                <IconButton
                    onClick={() => { setCreateTemplateTask(true); initializeTemplateTask(); setEditRow(-1) }}
                    className={classes.save}
                >
                    <AddCircle />
                </IconButton>
            </Grid>}
            {createTemplateTask && <TableRow>
                <TableCell>
                    <Input
                        multiline={true}
                        help_text={""}
                        label_text={"Name"}
                        value={taskName}
                        onTextChange={(text: string) => setTaskName(text)}
                        type="text"
                        width={100}
                    />
                </TableCell>
                <EditColumn
                    abort={true}
                    index={-1}
                    saveChanges={() => AddTemplateTask()}
                    editRow={-1}
                    setEditRow={() => setCreateTemplateTask(false)}
                />
                <TableCell></TableCell>
            </TableRow>}
            <Table
                headers={["Task name", "Edit"]}
                rows={templateTasks}
                generateBody={(row: ChecklistTemplateTaskDto, index: number) => generateTableBody(row, index)}
                pagination={true}
                defaultRowsPerPage={10}
                skipIndexCol={true}
            />
            <Dialog
                visible={deleteDialogOpen}
                max_width={"sm"}
                title={"Are you sure you want to delete this template task"}
                context={<></>}
                onClose={(status: Boolean) => { status === true ? onDeleteDialogConfirm() : setDeleteDialogOpen(false) }}
                strict={false}
                show_options={true}
            />
        </Grid >
    )
}

export default ChecklistTemplate;