import { useState } from "react";
import { Grid } from '@mui/material';
import { LinearProgress, Tabs } from '../../MaterialUiComponents';

import ChecklistsOverview from "./ChecklistsOverview";
import ChecklistTemplate from "./ChecklistTemplate";

const Checklists = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState<number>(0);

    return (
        <Grid>
            {loading && <LinearProgress />}
            <Tabs
                fixedTabs
                tabNames={["Checklists", "Template"]}
                tabValue={tabValue}
                onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
            />
            {tabValue === 0 && <ChecklistsOverview setLoading={setLoading} loading={loading} />}
            {tabValue === 1 && <ChecklistTemplate setLoading={setLoading} />}
        </Grid >
    )
}

export default Checklists;