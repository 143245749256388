import { settings } from '../config';
import { HangarChecklistDto, ChecklistTaskDto, ChecklistTemplateTaskDto } from '../types/checklist';
import { getToken } from './../global/TokenValidator';

class Auth {
    AddTemplateTask = async (taskName: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Checklist/AddTemplateTask`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                TaskName: taskName
            })
        }).then(x => {
            return x.json()
        })
    }
    DeleteTemplateTask = async (taskId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Checklist/DeleteTemplateTask/${taskId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    UpdateTemplateTask = async (taskId: number, taskName: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Checklist/UpdateTemplateTask`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                TaskId: taskId,
                TaskName: taskName
            })
        }).then(x => {
            return x.json()
        })
    }
    GetChecklistTemplateTasks = async (): Promise<{ status: boolean, message: string, data: ChecklistTemplateTaskDto[] }> => {
        return fetch(`${settings.HOST}/Checklist/GetChecklistTemplateTasks`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    GetAllChecklists = async (): Promise<{ status: boolean, message: string, data: HangarChecklistDto[] }> => {
        return fetch(`${settings.HOST}/Checklist/GetAllChecklists`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetChecklist = async (tailNumber: string): Promise<{ status: boolean, message: string, checklist: HangarChecklistDto, tasks: ChecklistTaskDto[] }> => {
        return fetch(`${settings.HOST}/Checklist/GetChecklist/${tailNumber}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    UpdateChecklist = async (checklistID: number, tailNumber: string, description: string, status: boolean, inputDate: Date, outputDate: Date): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Checklist/UpdateChecklist`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                ChecklistID: checklistID,
                TailNumber: tailNumber,
                Description: description,
                Status: status,
                InputDate: inputDate,
                OutputDate: outputDate
            })
        }).then(x => {
            return x.json()
        })
    }
    GetChecklistsTasks = async (checklistID: number): Promise<{ status: boolean, message: string, data: ChecklistTaskDto[] }> => {
        return fetch(`${settings.HOST}/Checklist/GetChecklistTasks/${checklistID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    UpdateChecklistTask = async (taskId: number, isDone: boolean, isApplicable: boolean): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Checklist/UpdateChecklistTask`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                TaskId: taskId,
                IsDone: isDone,
                IsApplicable: isApplicable
            })
        }).then(x => {
            return x.json()
        })
    }
    CreateChecklist = async (tailNumber: string, description: string, status: boolean, inDate: Date, outDate: Date): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Checklist/CreateChecklist`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                TailNumber: tailNumber,
                Description: description,
                Status: status,
                InDate: inDate,
                OutDate: outDate
            })
        }).then(x => {
            return x.json()
        })
    }
    InactiateChecklist = async (checklistId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Checklist/InactivateChecklist/${checklistId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    DeleteChecklist = async (checklistId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Checklist/DeleteChecklist/${checklistId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
}
export default new Auth();;