import { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { Typography, Dialog, LinearProgress, Input } from '../../MaterialUiComponents';
import SecurityApi from '../../api/security';
import { SmallPackageInformation } from '../../components';
import { AircraftInHangar } from '../../types/security';
import StaffApi from '../../api/staffmanagement';
import { StaffManagementBays } from '../../types/staffManagement';
import { HangarChecklistDto } from '../../types/checklist';
import ChecklistDetailsModal from '../Checklists/ChecklistDetailsModal';
import ChecklistsApi from '../../api/checklists';
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
import ChecklistApi from "../../api/checklists";
import { Datepicker } from '@mobiscroll/react';
import OverviewApi from '../../api/overview';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { PackageInformationObj } from '../../types/overview';

interface selectedVariables {
  description: string;
  status: boolean;
  inDate: Date;
  outDate: Date;
}

function HangarOverview() {
  const [hangarOverviewleftCol, setHangarOverviewleftCol] = useState(Array<AircraftInHangar>());
  const [hangarOverviewRightCol, setHangarOverviewRightCol] = useState(Array<AircraftInHangar>());
  const [staffManagementBays, setStaffManagementBays] = useState<StaffManagementBays[]>([]);
  const [checklist, setChecklist] = useState<HangarChecklistDto>();
  const [checklistDialogOpen, setChecklistDialogOpen] = useState<boolean>(false);
  const [createChecklistDialog, setCreateChecklistDialog] = useState<boolean>(false);
  const [createChecklistFields, setCreateChecklistField] = useState<boolean>(false);
  const [selectedTailNumber, setSelectedTailNumber] = useState<string>("");
  const [selected, setSelected] = useState<selectedVariables>({ description: "", status: false, inDate: new Date(), outDate: new Date() });
  const [loading, setLoading] = useState<boolean>(false);
  const store = useSelector((state: RootState) => state);
  const isChecklistAdmin = store.user.me.user_clearence_groups.includes("Checklist Edit");

  useEffect(() => {
    setLoading(true);
    refresh();
  }, []);

  const refresh = () => {
    SecurityApi.AircraftInHangar().then(res => {
      sortHangarOverview(res.data);
      StaffApi.GetAllStaffManagement().then(res => {
        setStaffManagementBays(res.data);
        setLoading(false);
      });
    });
  }

  const sortHangarOverview = (hangarOverview: AircraftInHangar[]): void => {
    const leftCol = hangarOverview.filter((x) => x.bay_name === 'Bay 4' || x.bay_name === 'Bay 5' || x.bay_name === 'Bay 6' || x.bay_name === 'Bay 7');
    const rightCol = hangarOverview.filter((x) => x.bay_name !== 'Bay 4' && x.bay_name !== 'Bay 5' && x.bay_name !== 'Bay 6' && x.bay_name !== 'Bay 7');

    const sortleft = leftCol.sort((a, b) => a.bay_name.localeCompare(b.bay_name));
    const sortRight = rightCol.sort((a, b) => a.bay_name.localeCompare(b.bay_name));

    setHangarOverviewleftCol(sortleft);
    setHangarOverviewRightCol(sortRight);
  }

  const getSupervisorNames = (currentBay: string): string[] =>
    staffManagementBays.reduce<string[]>(
      (acc, bay) =>
        bay.bay.toLowerCase() === currentBay.toLowerCase()
          ? acc.concat(
            (bay.areas.find(area => area.area.toUpperCase().includes("SUPERVISORS"))?.employyes_in_group || []).map(emp => emp.employee_name)
          )
          : acc,
      []
    );

  const getEarliestAndLatestDates = (rows: PackageInformationObj[]) => {
    const startDates = rows.map(row => new Date(row.wo_actual_start_date || row.wo_schedule_start_date)).filter(date => !isNaN(date.getTime()));
    const stopDates = rows.map(row => new Date(row.wo_actual_stop_date || row.wo_schedule_stop_date)).filter(date => !isNaN(date.getTime()));

    const earliestStart = startDates.length
      ? new Date(Math.min(...startDates.map(d => d.getTime())))
      : new Date();

    const latestStop = stopDates.length
      ? new Date(Math.max(...stopDates.map(d => d.getTime())))
      : new Date();
    return { earliestStart, latestStop };
  };

  const getChecklistInfo = (tailNumber: string) => {
    if (tailNumber !== "") {
      OverviewApi.GetPackageInformationByTailNumber(tailNumber, ["IN WORK"], false).then(res => {
        if (res.status === true) {
          const { earliestStart, latestStop } = getEarliestAndLatestDates(res.data);
          setSelected(prev => ({
            ...prev,
            inDate: new Date(earliestStart),
            outDate: new Date(latestStop),
          }));
        }
      })
    }
    setLoading(true);
    ChecklistsApi.GetChecklist(tailNumber).then(res => {
      if (res.status === true) {
        setChecklist(res.checklist);
        setChecklistDialogOpen(true);
      }
      else {
        if (isChecklistAdmin) {
          setCreateChecklistDialog(true);
          setSelectedTailNumber(tailNumber);
        }
      }
      setLoading(false);
    });
  }

  const onCreateChecklist = (status: boolean) => {
    if (status && !createChecklistFields) {
      setCreateChecklistField(true);
    }
    else if (status && createChecklistFields) {
      createChecklist();
    }
    else {
      setCreateChecklistField(false);
      setChecklistDialogOpen(false);
      setCreateChecklistDialog(false);
    }
  }

  const createChecklist = () => {
    setLoading(true);
    ChecklistApi.CreateChecklist(selectedTailNumber, selected.description, selected.status, new Date(selected.inDate), new Date(selected.outDate)).then(res => {
      refresh();
      setChecklistDialogOpen(false);
      setCreateChecklistDialog(false);
      getChecklistInfo(selectedTailNumber);
    });
  }

  return (
    <Grid sx={{ maxWidth: '100%', mx: 'auto', px: 2, width: '100%' }}>
      {loading && <LinearProgress />}
      {/* Header */}
      <Typography variant="h1" align="center" text={"Hangar Overview"} />

      {/* Main Grid */}
      <Grid
        container
        spacing={1}
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          marginTop: 1,
        }}
      >
        {/* Left Column */}
        <Grid
          sx={{
            flex: '1 1 48%',
            minWidth: '300px',
            maxWidth: 'calc(50% - 8px)',
            paddingRight: 1,
          }}
        >
          {hangarOverviewleftCol.map((row, idx) => (
            <Paper key={idx} sx={{ mb: 1, p: 1 }}>
              <SmallPackageInformation
                packageInformation={row}
                supervisors={getSupervisorNames(row.bay_name)}
                onTailNumberClicked={() => getChecklistInfo(row.tail_number)}
              />
            </Paper>
          ))}
        </Grid>

        {/* Right Column */}
        <Grid
          sx={{
            flex: '1 1 48%',
            minWidth: '300px',
            maxWidth: 'calc(50% - 8px)',

          }}
        >
          {hangarOverviewRightCol.map((row, idx) => (
            <Paper key={idx} sx={{ mb: 1, p: 1 }}>
              <SmallPackageInformation
                packageInformation={row}
                supervisors={getSupervisorNames(row.bay_name)}
                onTailNumberClicked={() => getChecklistInfo(row.tail_number)}
              />
            </Paper>
          ))}
        </Grid>
      </Grid>
      <Dialog
        visible={checklistDialogOpen}
        max_width={"sm"}
        title={checklist?.TailNumber || ""}
        context={
          <ChecklistDetailsModal
            checklist={checklist}
            checkListDialogOpen={setChecklistDialogOpen}
            isChecklistAdmin={isChecklistAdmin}
            refreshChecklists={() => { }}
            setLoading={setLoading}
            loading={loading}
          />
        }
        onClose={(status: Boolean) => { setChecklistDialogOpen(false); setLoading(false) }}
        strict={false}
        show_options={false}
      />
      <Dialog
        visible={createChecklistDialog}
        max_width={"lg"}
        title={`Would you like to create a checklist for ${selectedTailNumber}` || ""}
        context={
          <Grid>
            {createChecklistFields &&
              <Grid container direction="row" justifyContent="center" style={{ marginTop: '28px' }}>
                <Grid style={{ marginRight: '18px' }} item xs={3} md={3} lg={3} xl={3}>
                  <Input
                    multiline={false}
                    help_text={""}
                    label_text={"Description"}
                    value={selected.description}
                    onTextChange={(text: string) =>
                      setSelected(prevState => ({
                        ...prevState,
                        description: text,
                      }))
                    }
                    type="text"
                    width={100}
                  />
                </Grid>
                <Grid item xs={2} md={2} lg={2} xl={2} style={{ marginRight: '18px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label
                      htmlFor="datepicker-id"
                      style={{
                        whiteSpace: 'nowrap',
                        fontSize: '0.9rem',
                        lineHeight: 1.5,
                      }}
                    >
                      In
                    </label>
                    <Datepicker
                      inputProps={{
                        id: 'datepicker-id',
                        placeholder: 'Select date',
                      }}
                      controls={['calendar']}
                      display="anchored"
                      touchUi={true}
                      value={selected.inDate}
                      onChange={(e: MbscCalendarEvent) => {
                        setSelected((prevState) => ({
                          ...prevState,
                          inDate: e.value,
                        }));
                      }}
                      themeVariant="light"
                      dateFormat="DD.MM.YYYY"
                    />
                  </div>
                </Grid>
                <Grid item xs={2} md={2} lg={2} xl={2} style={{ marginRight: '18px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label
                      htmlFor="datepicker-id"
                      style={{
                        whiteSpace: 'nowrap',
                        fontSize: '0.9rem',
                        lineHeight: 1.5,
                      }}
                    >
                      Out
                    </label>
                  <Datepicker
                    controls={['calendar']}
                    inputProps={{
                      placeholder: 'Select date'
                    }}
                    display='anchored'
                    touchUi={true}
                    value={selected.outDate}
                    onChange={(e: MbscCalendarEvent) => {
                      setSelected(prevState => ({
                        ...prevState,
                        outDate: e.value,
                      }))
                    }
                    }
                    themeVariant='light'
                    dateFormat='DD.MM.YYYY'
                  />
                </div>
                </Grid>
              </Grid>
            }
          </Grid>
        }
        onClose={(status: boolean) => onCreateChecklist(status)}
        strict={false}
        show_options={true}
      />
    </Grid>
  );
};
export default HangarOverview;