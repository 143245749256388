import React, { FunctionComponent } from 'react';
import { Checkbox, Badge, Theme, Grid } from '@mui/material';
import { Person, ShoppingCart, PriorityHigh, LocalAirport, HourglassFull } from '@mui/icons-material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Tooltip } from '../../MaterialUiComponents';
import { Event, PlannedTask } from '../../types/tasks';
import { makeStyles } from '@mui/styles';

interface StyleProps {
    backgroundColor?: string;
    textColor?: string;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    calendarTaskAssignmentDefault: props => ({
        backgroundColor: props.backgroundColor || theme.palette.secondary.main,
        color: props.textColor || theme.palette.text.primary,
        borderColor: theme.palette.primary.main,
        borderRadius: '15px',
        borderTopStyle: 'ridge',
        minHeight: '50px',
        maxHeight: '80px',
    }),
    CheckBox: {
        float: 'left',
        marginTop: "-9px",
        marginLeft: "5px",
        marginRight: "-5px",
        marginBottom: 0,
        position: 'relative',
        color: 'inherit'
    },
    calendarTaskIcon: {
        float: 'left',
        marginTop: "5px",
        marginLeft: "8px",
        marginBottom: 0,
        position: 'relative',
    },
    calendarTaskText: {
        margin: 0,
        marginRight: 5,
        textAlign: 'center',
        textOverflow: 'ellipsis'
    },
    calendarTaskDefault: {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: '15px',
        borderTopStyle: 'ridge',
        minHeight: '50px',
        maxHeight: '80px',
    },
    calendarTaskComplete: {
        backgroundColor: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        borderRadius: '15px',
        borderTopStyle: 'ridge',
        minHeight: '50px',
        maxHeight: '80px',
    },
    calendarTaskActive: {
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: '15px',
        borderTopStyle: 'ridge',
        minHeight: '50px',
        maxHeight: '80px',
    },
    calendarTaskPause: {
        backgroundColor: '#FFFF66',
        borderColor: '#FFFF66',
        borderRadius: '15px',
        borderTopStyle: 'ridge',
        minHeight: '50px',
        maxHeight: '80px',
        color: theme.palette.text.primary
    },
    calendarTaskCancel: {
        backgroundColor: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        borderRadius: '15px',
        borderTopStyle: 'ridge',
        minHeight: '50px',
        maxHeight: '80px',
    },
    calendarTaskCommit: {
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: '15px',
        borderTopStyle: 'ridge',
        minHeight: '50px',
        maxHeight: '80px',
    },
    smallText: {
        fontSize: theme.spacing(1.5),
        textAlign: 'center',
    },
    activeTask: {
        border: '5px solid black',
        margin: '0.5em',
    },
    calendarHeader: {
        backgroundColor: 'black'
    },
    taskPriority: {
        width: '25px',
        height: '25px', 
        backgroundColor: 'red',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center', 
        borderRadius: '50%',
        textAlign: 'center',
        flexShrink: '0',
        marginTop: "5px",
        marginLeft: "8px",
      },
}));

type CalendarTaskProps = {
    isTaskAssignment?: boolean,
    edit: Boolean,
    onCheckboxClicked: Function
    event: Event
    checked: boolean
    clickedTaskDate: PlannedTask | undefined,
    isLinePlanner: boolean | undefined
}

const CalendarTask: FunctionComponent<CalendarTaskProps> = React.memo(({ edit = true, onCheckboxClicked, event, checked, clickedTaskDate, isLinePlanner, isTaskAssignment }) => {
    const taskDetails = event.task_details;
    const assignedEmployees = taskDetails.assigned_employees_on_calendar;
    const status = taskDetails?.status;

    // Prevent open task dialog from opening when clicking checkbox
    const onTaskChecked = (mouseEvent: React.MouseEvent) => {
        mouseEvent.stopPropagation();
        onCheckboxClicked(event, !checked);
    }
    
    const backgroundColor = event.task_details.color;
    const textColor = event.task_details.color === "#FFFFFF" ? "#000000" : "#FFFFFF"; //changing the text color to black for tasks with a  bone-white background.
    const classes = useStyles({ backgroundColor, textColor });

    let taskPriority = "";
    if (event.task_priority === "P01") {
      taskPriority = "P1";
    }
    else if (event.task_priority === "P02") {
      taskPriority = "P2";
    }
    else if (event.task_priority === "P03") {
      taskPriority = "P3";
    }

    return (
        <div id={"calendar-task-" + event.scheduled_task_id} className={clickedTaskDate !== undefined && clickedTaskDate.scheduled_task_id === event.scheduled_task_id ? classes.activeTask : ''}>
            <div className={event.task_details.color ? classes.calendarTaskAssignmentDefault : (status === "ACTV" ? classes.calendarTaskActive : status === "COMPLETE" ? classes.calendarTaskComplete : status === "PAUSE" ? classes.calendarTaskPause : status === "IN WORK" ? classes.calendarTaskDefault : status === "CANCEL" ? classes.calendarTaskCancel : status === "COMMIT" ? classes.calendarTaskCommit : status === "HEADER" ? classes.calendarHeader : '')}>
                {edit &&
                    <Checkbox className={classes.CheckBox} checked={checked} color="default" onMouseDown={(e) => { e.stopPropagation() }} onClick={(e) => { onTaskChecked(e) }} />
                }
                {assignedEmployees !== null && assignedEmployees.length > 0 &&
                    <Tooltip title={assignedEmployees} type='primary' placement="top-start"><Person className={classes.calendarTaskIcon} /></Tooltip>
                }
                {event.task_details.part_amounts > 0 &&
                    <Tooltip title={`${event.task_details.part_amounts} parts`} type='primary' placement="top-start">
                        <Badge badgeContent={event.task_details.part_amounts} color="primary" className={classes.calendarTaskIcon} >
                            <ShoppingCart color={event.task_details.part_not_available ? 'primary' : 'error'} />
                        </Badge>
                    </Tooltip>
                }
                {taskDetails.is_messages &&
                    <Tooltip title={'Message'} type='primary' placement="top-start"><NotificationsActiveIcon color={taskDetails.is_message_unseen ? 'error' : 'inherit'} className={classes.calendarTaskIcon} /></Tooltip>
                }
                {taskDetails.is_task_critical &&
                    <Tooltip title={'Critical Work'} type='primary' placement="top-start"><PriorityHigh color={taskDetails.is_task_critical ? 'error' : 'inherit'} className={classes.calendarTaskIcon} /></Tooltip>
                }
                {taskDetails.is_on_hold &&
                    <Tooltip title={'On hold'} type='primary' placement="top-start"><HourglassFull color={taskDetails.status === "CANCEL" ? 'inherit' : 'error'} className={classes.calendarTaskIcon} /></Tooltip>
                }
                {taskDetails.is_on_time === false &&
                    <Tooltip title={'Aircraft is not on time, Reschedule'} type='primary' placement="top-start"><LocalAirport color={taskDetails.status === "CANCEL" ? 'inherit' : 'error'} className={classes.calendarTaskIcon} /></Tooltip>
                }
                {isTaskAssignment &&
                    <p className={classes.calendarTaskText}>
                        {taskDetails.wp_line_no !== undefined &&
                            <span className={classes.smallText}>
                                Task No: {taskDetails.wp_line_no}
                            </span>}
                    </p>
                }
                {taskPriority !== "" &&
                <Grid className={classes.taskPriority}>
                    {taskPriority}
                </Grid>
                }
                {isTaskAssignment &&
                    <p className={classes.calendarTaskText}>
                        {taskDetails.wp_line_no !== undefined &&
                            <span className={classes.smallText}>
                                Status: {taskDetails.status}
                            </span>}
                    </p>
                }
                <p className={classes.calendarTaskText}>
                    {taskDetails.mxi_task_id !== 0 &&
                        <span className={classes.smallText}>
                            #{taskDetails.mxi_task_id}
                        </span>}
                    <Tooltip title={isLinePlanner ? taskDetails?.due_date_text : ""} type='primary' placement='top-end'>
                        {event.title}
                    </Tooltip>
                </p>
            </div>
        </div>
    );
});
export default CalendarTask;
