
interface Request {
    request_id?: number;
    created_date?: Date;
    created_date_text?: string;
    created_by_employee_id?: number;
    last_modified_date?: Date;
    last_modified_by_employee_id?: number;
    part_number: string;
    part_name: string;
    dash_numbers: string;
    cmm_chapters: string;
    description: string;
    class_type: string;
    ata_chapters: string;
    special_training: string;
    manuals_needed_by_shop: string;
    is_inspection_test: boolean;
    is_repair: boolean;
    is_oh: boolean;
    is_mods: boolean;
    accepted_date_text?: string;
    accepted_date: Date;
    accepted_text: string;
    auto_generated_accepted_text?: string;
    is_deleted?: boolean;
    jira_link?: string;
    capability_sub_assys: Array<CapabilitySubAssys>;
    capability_tools: Array<CapabilityTools>;
    shops: Array<CapabilityShops>;
    version_nr?: number;
    rating: RatingEnum;
    component_name: string;
    other_text: string;
}

export enum RatingEnum{
    Unknown = 0,
    A = 1,
    B = 2,
    C = 3
}
interface CapabilitySubAssys {
    id?: number;
    created_date?: Date;
    created_date_text?: string;
    created_by_employee_id?: number;
    last_modified_date?: Date;
    capability_request_id?: number;
    part_number: string;
    accepted_text: string;
    is_deleted?: Boolean;
}
interface CapabilityTools {
    part_number: string;
    tool_name: string;
}

interface ATAChapter {
    id: number;
    created_date: Date;
    created_by_employee_id: number;
    last_modified_date?: Date;
    last_modified_by_employee_id?: number;
    ata_chapter: string;
    rating: string;
    is_deleted: boolean;
    deleted_reason: string;
}


interface CapabilityShops {
    id?: number;
    created_date?: Date;
    created_by_employee_id?: number;
    last_modified_date?: Date;
    last_modified_by_employee_id?: number;
    shop_name: string;
    is_deleted?: boolean;
    deleted_reason?: string;
}

interface MutableRequest {
    request?: Request;
}
export type {
    ATAChapter,
    Request,
    MutableRequest,
    CapabilitySubAssys,
    CapabilityTools,
}